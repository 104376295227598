import { DefaultButton, FontWeights, getTheme, IMessageBarStyles, mergeStyleSets, MessageBar, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Redirect } from "react-router";
import Helper from "../../../Helpers/Helper";
import IAuthCodeRequest from "../Interfaces/IAuthCodeRequest";
import { IWebFormUpdateLink } from "../Interfaces/IWebFormUpdateLink";
import AuthCodeService from "../Services/AuthCodeService";
import "../../../web.css";
import { getTokenClaims, isEmail } from "../../../Helpers/Utils";
import useAuth from "../../../hooks/useAuth";
import { textFieldStyle } from "../../CustomTemplates/CustomForm/RHFControls/Styles";
import ISendAuthCodeRequest from "../Interfaces/ISendAuthCodeRequest";
import { useBranding } from "../../../hooks/useBranding";
import DocumentService from "../../../services/document";
import { useDocument } from "../../../hooks/useDocument";
import UserService, { IGetuserprofileRequest } from "../../../services/User";
import HttpRequest from "../../../services/HttpRequest";
import Captcha from "./Captcha";
import { getIsApplication } from "../../DocumentLink/FormDocumentLink.data";
import { useSSO } from "../../../hooks/useSSO";
import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "../../../services/auth";

const theme = getTheme();

const messageBarFailedStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#ffd5d5",
    },
};
const messageBarSuccessStyles: IMessageBarStyles = {
    root: {
        backgroundColor: "#acffac",
    },
};


function AuthCode(props: any) {
    const { authCode, setAuthCode, setAccessValid } = props
    const { branding, getAppSettings } = useBranding()

    const [isValid, setIsValid] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [logo, setLogo] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [fontColor, setFontColor] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isEmailDisable, setIsEmailDisable] = useState(false);
    const [templateName, setTemplateName] = useState("")
    const [instructions, setInstructions] = useState("")
    const [redirectUrl, setRedirectUrl] = useState("")

    const [captchaEnabled, setCaptchaEnabled] = useState(false)
    const { isAuthenticated, user, isAuthLoaded } = useAuth();
    const { setSSO, enabled: ssoEnabled, validateUser, validateSSO, isLoaded: _isSSOLoaded, isValid: isSSOValid } = useSSO()
    const { isAuthenticated: auth0Authenticated, user: auth0User } = useAuth0()
    const [dataJSON, setDataJSON] = useState<IWebFormUpdateLink>({} as IWebFormUpdateLink)

    const isSSOLoaded = _isSSOLoaded && !isSSOValid
    const getBrandingInformation = async (username: string, templateId: string) => {

        const request = {
            TemplateId: templateId
        }

        let { data }: any = await HttpRequest.getWithSession('GetAudienceMasterByTemplateId', request)
        const response = data

        if (response !== undefined) {
            if (response[0] !== undefined) {
                var sColor = "#3e7bbb";
                if (response[0].BackgroundColor !== null && response[0].BackgroundColor !== undefined && response[0].BackgroundColor !== "") {
                    sColor = response[0].BackgroundColor;
                }

                var fColor = "#fff";
                if (response[0].CompanyForeColor !== null && response[0].CompanyForeColor !== undefined && response[0].CompanyForeColor !== "") {
                    fColor = response[0].CompanyForeColor;
                }
                setFontColor(fColor);
                setBackgroundColor(sColor);
                setLogo(response[0].Logo);
                setCompanyName(response[0].CompanyName);
                if (response[0].Logo !== null && response[0].Logo !== undefined) {
                    var link = document.createElement("link");
                    var oldLink = document.querySelector("link[rel*='icon']");
                    var oldName = document.querySelector("title");
                    link.id = "dynamic-favicon";
                    link.rel = "icon";
                    link.href = "data:image/png;base64," + response[0].Logo;

                    if (oldLink) {
                        document.head.removeChild(oldLink);
                        document.head.removeChild(oldName!);
                    }
                    document.head.appendChild(link);
                    document.head.appendChild(link);
                    document.title = response[0].Name! || "";
                }
            }
        }
    };
    const contentStyles = mergeStyleSets({
        modalContainer: {
            width: '28vw'
        },
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        accessButton: {
            display: 'flex',
            justifyContent: isMobile ? 'normal' : 'end',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            gap: isMobile ? '0.7rem' : '0.5rem'
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${backgroundColor}`,
                color: branding.theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
                justifyContent: "center",
            },
        ],
        actionButtons: {
            display: 'grid',
            gridTemplateColumns: isMobile ? 'auto' : 'auto auto',
            gap: '10px',
            alignSelf: 'end'
        },
        instructions: {
            padding: '4px'
        },
        body: {
            padding: "10px",
        },
        footer: {
            padding: "10px",
        },
    });
    const { setProfile } = useDocument()

    useEffect(() => {
        if (isAuthenticated) {
            const url_string = props.location.state.href;
            const url = new URL(url_string);
            const token = url.searchParams.get("token");
            const isApplication = url.pathname.toString().indexOf("application") > 0;
            var params = getTokenClaims(token!);
            let datajson: IWebFormUpdateLink = {} as IWebFormUpdateLink;
            if (params !== undefined) {
                datajson = params;
                setDataJSON(datajson)
                if (!isApplication) {
                    setEmailAddress(params.TargetUser);
                    setIsEmailDisable(true);
                }
            }
            getAppSettings()
            const validateCode = async () => {

                const request: IAuthCodeRequest = {
                    AuthCode: authCode,
                    DocumentId: datajson.DocumentId,
                    OnBehalfUser: sessionStorage.getItem("OnBehalfUser")!,
                    Path: await Helper.getSite(),
                    Operation: 'validate',
                    TemplateOwner: isApplication ? datajson.TargetUser : datajson.ownerUser,
                    TemplateId: datajson.TemplateId
                };

                await AuthCodeService.Validate(request)
                    .then(async () => {
                        const request: IGetuserprofileRequest = {
                            UserName: isApplication ? datajson.TargetUser : datajson.ownerUser,
                            Profile: sessionStorage.getItem("OnBehalfUser")!,
                        }
                        await UserService.GetUserProfile(request)
                            .then((data) => {
                                setProfile(data)
                                setAccessValid(data.Success)
                            })
                            .catch(() => {
                                onValidatingError()
                                return
                            })
                            .finally(() => {
                                setIsLoading(false)
                            })
                    })
                    .catch()
                    .finally()

            }
            setIsLoading(true)
            DocumentService.getDocumentMetadata({ TemplateId: datajson.TemplateId, UserName: datajson.User }).then(async ({ data }: any) => {
                setTemplateName(data.DisplayName)
                setInstructions(data.accessCodeInstructions?.trim() ?? '(You are required to enter an access code)')
                setRedirectUrl(data.RedirectUrl)
                if (!data.EnabledCaptcha) {
                    validateCode()
                } else if (getIsApplication()) {
                    setCaptchaEnabled(true)
                }
                setIsLoading(false)
            })
            getBrandingInformation(datajson.User, datajson.TemplateId).then((data) => { });
        }
    }, [isAuthenticated]);

    const cleaStorage = () => {
        setAuthCode('')
        sessionStorage.removeItem('isAccessCodeValid')

    }

    useEffect(() => {
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        const params = getTokenClaims(token!);
        const { TemplateId, User: UserName } = params
        AuthService.validateSSO({ TemplateId, UserName }).then((claims) => {
            setProfile(claims)
            sessionStorage.setItem("OnBehalfUser", claims.Email);
            setSSO({ enabled: true, isValid: true, isLoaded: true })
            setAccessValid(true)
        }).catch(() => {
            return
        })
    }, [setProfile])

    if (isValid === true) {
        return <Redirect to={props.location.state.referrer} />;
    }

    if (props.location.state === undefined) {
        return <Redirect to="/app" />;
    }

    const sendCode = async () => {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;

        if (!emailAddress.match(mailformat)) {
            displayError("Invalid Email Address.");
            return;
        }
        if (firstName === undefined || firstName === null || firstName.trim() === "") {
            displayError("First Name Required.");
            return;
        }
        if (lastName === undefined || lastName === null || lastName.trim() === "") {
            displayError("Last Name Required.");
            return;
        }
        setIsLoading(true)
        const url_string = props.location.state.href;
        const url = new URL(url_string);
        const token = url.searchParams.get("token");
        var params = getTokenClaims(token!);

        let datajson: IWebFormUpdateLink = {} as IWebFormUpdateLink;
        if (params !== undefined) {
            datajson = params; //JSON.parse(database64) as IWebFormUpdateLink
        }

        const isApplication = url.pathname.toString().indexOf("application") > 0;

        const request: ISendAuthCodeRequest = {
            AuthCode: authCode,
            DocumentId: datajson.DocumentId,
            Operation: "Create",
            Path: await Helper.getSite(),
            TemplateId: datajson.TemplateId,
            LoggedUser: isApplication ? datajson.TargetUser : datajson.ownerUser,
            TemplateOwner: datajson.ownerUser,
            OnBehalfUser: emailAddress,
            UserFirstName: firstName,
            UserLastName: lastName,
        };

        await AuthCodeService.Send(request)
            .then((response) => {
                sessionStorage.setItem("OnBehalfUser", emailAddress);
                setIsLoading(false)
                setShowSuccessMessage(true);
                const authCodeLoginSubmitElement = document.getElementById('authCodeLoginSubmit');
                if (authCodeLoginSubmitElement) {
                    authCodeLoginSubmitElement.style.display = 'flex';
                }
                const txtAuthCodeElement = document.getElementById('txtAuthCode');
                if (txtAuthCodeElement) {
                    txtAuthCodeElement.focus();
                }
                setMessage("A new access code has been sent to recipient email address.");
                setTimeout(() => {
                    setShowSuccessMessage(false);
                    setMessage("");
                }, 5000);
            })
            .catch(() => {
                setIsLoading(false)
                setShowErrorMessage(true);
                setMessage("Error when sending access code.");
                setTimeout(() => {
                    setShowErrorMessage(false);
                    setMessage("");
                }, 5000);
            })
            .finally()
    };

    const displayError = (message: string) => {
        setShowErrorMessage(true);
        setMessage(message);
        setTimeout(() => {
            setShowErrorMessage(false);
            setMessage("");
        }, 5000);
    };

    const onClickCancel = () => {
        /* console.log("redirectUrl")
         console.log(redirectUrl.trim().length)
         console.log(redirectUrl)*/
        const redirect = redirectUrl === '' ? window.location.origin + "/app" : redirectUrl
        window.location.assign(redirect);
    }



    /**
     * on Validating Error
     */
    function onValidatingError() {
        setMessage(`Error loading User Profile ${sessionStorage.getItem("OnBehalfUser")!}`);
        cleaStorage()
        setAccessValid(false)
        setIsValidating(false);
        setShowErrorMessage(true);
        setTimeout(function () {
            setShowErrorMessage(false);
            setMessage("");
        }, 15000);
    }

    const validate = async (): Promise<any> => {
        if (!isEmail(emailAddress)) {
            displayError("Invalid Email Address.");
            return;
        }
        if (firstName === undefined || firstName === null || firstName.trim() === "") {
            displayError("First Name Required.");
            return;
        }
        if (lastName === undefined || lastName === null || lastName.trim() === "") {
            displayError("Last Name Required.");
            return;
        }
        setIsValidating(true);
        const url_string = props.location.state.href;
        const url = new URL(url_string);
        const token = url.searchParams.get("token");

        var params = getTokenClaims(token!);

        let datajson: IWebFormUpdateLink = {} as IWebFormUpdateLink;
        if (params !== undefined) {
            datajson = params; //JSON.parse(database64) as IWebFormUpdateLink
        }

        const isApplication = url.pathname.toString().indexOf("application") > 0;
        const request: IAuthCodeRequest = {
            AuthCode: authCode,
            DocumentId: datajson.DocumentId,
            OnBehalfUser: sessionStorage.getItem("OnBehalfUser")!,
            Operation: "validate",
            TemplateId: datajson.TemplateId,
            Path: await Helper.getSite(),
            TemplateOwner: isApplication ? datajson.TargetUser : datajson.ownerUser,
        };

        await AuthCodeService.Validate(request)
            .then(async (response) => {
                const request: IGetuserprofileRequest = {
                    UserName: isApplication ? datajson.TargetUser : datajson.ownerUser,
                    Profile: emailAddress,
                }
                await UserService.GetUserProfile(request)
                    .then((data) => {
                        sessionStorage.setItem("isAccessCodeValid", authCode);
                        setIsValidating(false);
                        setProfile(data)
                        setAccessValid(true)
                    })
                    .catch(() => {
                        onValidatingError()
                        return
                    })
            })
            .catch(() => {
                setAuthCode("");
                setAccessValid(false)
                setIsValidating(false);
                cleaStorage()
                setShowErrorMessage(true);
                setMessage("Error validating unique access code.");
                setTimeout(function () {
                    setShowErrorMessage(false);
                    setMessage("");
                }, 5000);
            })
            .finally()

    }

    return (
        <>

            <Modal isOpen={true} isBlocking={true} containerClassName={isMobile ? '' : contentStyles.modalContainer}>
                <div className="ms-Grid" dir="ltr" >
                    <div className="ms-Grid-row">
                        <div style={{ padding: "0px" }} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Stack style={{ textAlign: "center" }}>
                                <div className={contentStyles.header}>
                                    <span id={"lblAuthCode"}>User Access</span>
                                </div>
                                <p className={contentStyles.instructions}>
                                    {instructions}
                                </p>
                            </Stack>
                        </div>
                    </div>
                    <div className="ms-Grid-row">

                        {!isValidating && !isLoading ? (
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack>
                                    <div className={contentStyles.body}>
                                        {showErrorMessage ? <MessageBar styles={messageBarFailedStyles}>{message}</MessageBar> : null}
                                        {showSuccessMessage ? <MessageBar styles={messageBarSuccessStyles}>{message}</MessageBar> : null}

                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: isMobile ? "10px 0px" : "15px 0px" }}>
                                            <TextField
                                                id="emailAddress"
                                                label={"Email Address:"}
                                                maxLength={250}
                                                required
                                                disabled={isEmailDisable}
                                                value={emailAddress}
                                                styles={textFieldStyle}
                                                borderless
                                                title="Enter Email Address."
                                                onChange={(_, v) => {
                                                    setEmailAddress(v!);
                                                }}
                                            />
                                        </div>
                                        {!isSSOLoaded ? <div className="ms-Grid-row">
                                            <div className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12 ${contentStyles.accessButton}`}>
                                                <DefaultButton text={"Cancel"} title={"Cancel."} onClick={onClickCancel} />
                                                <PrimaryButton onClick={() => validateUser({
                                                    onBehalf: emailAddress,
                                                    owner: getIsApplication()? dataJSON.TargetUser : dataJSON.ownerUser,
                                                    templateId: dataJSON.TemplateId
                                                })} text={"Access"} title={authCode !== "" ? "Access." : "Enter email to activate button."} disabled={emailAddress.length === 0} />
                                            </div>
                                        </div> : null}
                                        {isSSOLoaded ?
                                            <>
                                                {!captchaEnabled ?
                                                    <>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: isMobile ? "10px 0px" : "15px 0px" }}>
                                                            <TextField
                                                                id="firstName"
                                                                label={"First Name:"}
                                                                maxLength={250}
                                                                required
                                                                value={firstName}
                                                                styles={textFieldStyle}
                                                                borderless
                                                                title="Enter First Name."
                                                                onChange={(_, v) => {
                                                                    setFirstName(v!);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: isMobile ? "10px 0px" : "15px 0px" }}>
                                                            <TextField
                                                                id="lastName"
                                                                label={"Last Name:"}
                                                                maxLength={250}
                                                                required
                                                                value={lastName}
                                                                styles={textFieldStyle}
                                                                borderless
                                                                title="Enter Last Name."
                                                                onChange={(_, v) => {
                                                                    setLastName(v!);
                                                                }}
                                                            />
                                                        </div>
                                                    </> : null}
                                                {!captchaEnabled ?

                                                    <>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: isMobile ? "10px 0px" : "10px 0px 15px 0px", display: "grid", position: "relative", gridTemplateColumns: "auto auto auto", gap: "10px", alignItems: "end" }}>
                                                            <PrimaryButton onClick={sendCode} text={"Get access code"} title={"Select get access code to send an access code to your email address."} />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: isMobile ? "10px 0px" : "10px 0px 15px 0px", display: "none", position: "relative", gridTemplateColumns: "auto", gap: "10px", alignItems: "end" }} id='authCodeLoginSubmit' >
                                                            <TextField id="txtAuthCode" label={"Enter Access Code:"}
                                                                maxLength={500} required value={authCode} onChange={(_, v) => {
                                                                    setAuthCode(v!);
                                                                }}
                                                                title="Please enter the access code sent to your email."
                                                            />
                                                            <div className={contentStyles.actionButtons}>
                                                                <PrimaryButton onClick={validate} text={"Submit"} title={authCode !== "" ? "Submit." : "Enter Access code to activate button."} disabled={authCode === ""} />
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: "10px 0px 15px 0px", display: "flex", justifyContent: "flex-end" }}>
                                                            <DefaultButton text={"Cancel"} title={"Cancel."} onClick={onClickCancel} />
                                                        </div>
                                                    </> :
                                                    <Captcha targetUser={emailAddress}
                                                        setAuthCodeLoading={setIsValidating}
                                                        setAccessValid={setAccessValid}
                                                        onClickCancel={onClickCancel}
                                                        captchaRequest={{
                                                            TemplateId: dataJSON?.TemplateId,
                                                            LoggedUser: dataJSON?.TargetUser,
                                                            OnBehalfUser: emailAddress,
                                                            UserFirstName: firstName,
                                                            UserLastName: lastName
                                                        }}
                                                        messageHandler={{
                                                            setShowErrorMessage,
                                                            setShowSuccessMessage,
                                                            setMessage
                                                        }} />}
                                            </> : null}
                                    </div>
                                </Stack>
                            </div>
                        ) : (
                                <Spinner size={SpinnerSize.large} label={isValidating ? "Validating  data..." : "Accessing..."} ariaLive="assertive" />
                            )}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AuthCode;
