import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { mergeStyleSets, getTheme, FontWeights } from "@fluentui/react/lib/Styling";
import { IColumn, SelectionMode, DetailsListLayoutMode, DetailsList, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps } from "@fluentui/react/lib/DetailsList";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import Form from "../Form/Form";
import Helper, { ITemplateLayoutRequest, IDeleteDocumentRequest, IDocumentVersionRequest, IDocumentRequest, ICheckinCheckoutDocumentOnlyRequest, IAdvanceSearch, IDocumentOnlyDataPointRequest, IDocumentOnlyPackageRequest, IDocumentOnlyAudienceRequest, IDocumentOnlyAudienceDetail } from "../../Helpers/Helper";
import { IContextualMenuItem, IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { IconButton, IButtonStyles, DefaultButton, PrimaryButton, ActionButton } from "@fluentui/react/lib/Button";
import VersionScreen, { IDocumentVersion } from "../Version/VersionScreen";
import "../../Custom.css";
import EmailWidget from "../Email/EmailWidget";
import Confirmation from "../Settings/Confirmation";
import PreviewScreen from "../Preview/PreviewScreen";
import { decode } from "base64-arraybuffer";
import { ReactNotificationOptions, store } from "react-notifications-component";
import { IRenderFunction } from "@fluentui/react/lib/Utilities";
import { TooltipHost } from "office-ui-fabric-react/lib/Tooltip";
import { Sticky, StickyPositionType } from "office-ui-fabric-react/lib/Sticky";
import { IDocument, ISubRecord } from "../../App";
import { CustomForm } from "../CustomTemplates/CustomForm/CustomForm";
import { IChoiceGroupOption, Icon, IIconProps, IIconStyles, Modal, Separator, Callout, Text, Toggle, DelayedRender, StackItem } from "@fluentui/react";
import Search from "../Search/Search";
import ShareDocument from "../CustomTemplates/ShareDocument";
import ActivityStatus from "../Activity/ActivityStatus";
import Notes from "./Notes";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { TextField } from "@fluentui/react/lib/TextField";
import UploadDocument from "../CustomTemplates/UploadDocument";
import Signers from "./Signers";
import Attachment from "../Attachments/Attachment";
import { useConstCallback, useBoolean } from "@uifabric/react-hooks";
import { Coachmark } from "@fluentui/react/lib/Coachmark";
import { TeachingBubbleContent } from "@fluentui/react/lib/TeachingBubble";
import { CheckboxVisibility, DirectionalHint } from "office-ui-fabric-react";
import Executed from "../Executed/Executed";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { isMobile } from "react-device-detect";
import "../../Custom.css";
import InfiniteScroll from "react-infinite-scroll-component";
import SubForms from "./SubForms";
import FormTemplateLink from "../TemplateLink/FormTemplateLink";
import { CheckoutOptionsVisibilityType } from "../CustomTemplates/Validations";
import DownloadConfirmation from "../../Helpers/DownloadConfrmation";
import Campaign from "../Campaign/Campaign";
import ReactTooltip from "react-tooltip";
import DraftList from "../DraftDocuments/DraftList";
import { AttachmentSettings } from "../../interfaces/IPropertyBagSettings";
import { useTranslation } from "react-i18next";
import { getRecordDescription } from "../../Helpers/functions";
import Copy from "./Copy";
import OutboundServices from "../OutboundIntegration/Services/OutboundServices";
import _ from "lodash";
import TestIntegrationModal from "../OutboundIntegration/TestIntegrationModal";
import { formatFromUTC, setGlobalDateLocale } from "../../Helpers/DateUtils";
import RenewalsAndAlertsWorkSpace from "../RenewalsAndAlerts/RenewalsAndAlertsWorkSpace";
import { IRecordRenewal } from "../RenewalsAndAlerts/RenewalsAndAlerts.service";
import RenewalsAndAlertsEdit from "../RenewalsAndAlerts/RenewalsAndAlertsEdit";
import { AppContext } from "../Provider";
import QueueModal from "../QueueModal/QueueModal";
import { useModal } from "../../hooks/useModal";
import FormDocumentLink from "../DocumentLink/FormDocumentLink"
import RestrictSubFormsService from "../CustomTemplates/subforms/RestrictSubForms/RestrictSubForms.service";
import { useBranding } from "../../hooks/useBranding";
import AudienceService from "../../services/audience";
import HttpRequest from "../../services/HttpRequest";

export class Version implements IDocumentVersion {
    Id = -1;
    DocumentId = -1;
    Version = -1;
    AccountId = -1;
    TemplateId = -1;
    UserName = "";
    Description = "";
    Data = "";
    DateCreated = "";
    DateUpdated = "";
    Name = "";
    MimeType = "";
    Culture = "";
    IconName = "";
    DisplayName = "";
    DATETIME = "";
}
export interface IPropertyBag {
    UniqueReferences?: string;
    Clon: string;
    emailMoniker: string;
    Released: boolean;
    Culture: string;
    CultureName: string;
    AllowDocX: boolean;
    AllowSendMail: boolean;
    DocumentCreation: boolean;
    DocumentUpdate: boolean;
    DocumentDelete: boolean;
    DocumentShare: boolean;
    TemplateEdit: boolean;
    TemplateShare: boolean;
    TemplateDelete: boolean;
    DefaultDescription: string;
    TemplateRemoves?: boolean;
    UseServiceAccount?: boolean;
    attachment: AttachmentSettings;
    SupressPreview: boolean;
    DocumentDelivery: string;
    RedirectURL: string;
    EnabledCaptcha?: boolean
    EnabledSSO?: boolean
}

const subR: ISubRecord[] = [];
export class Document implements IDocument {
    Executed = false;
    Id = -1;
    AccountId = -1;
    TemplateId = -1;
    UserName = "";
    Description = "";
    Data = "";
    DateCreated = "";
    DateUpdated = "";
    Name = "";
    MimeType = "";
    Culture = "";
    IconName = "";
    Versions = -1;
    IsCustom = false;
    LastActivity = "";
    Closed = false;
    Suspended = false;
    LastNote = "";
    Category = "";
    AllowCheckInOut = false;
    AllowDocumentUpdate = false;
    AllowESign = false;
    AllowNotification = false;
    CheckedOut = false;
    CheckedOutUser = "";
    CheckedOutDateTime = "";
    TemplatePropertyBag = "";
    Owner = false;
    CoOwner = false;
    Tickler = "";
    Attachments = 0;
    AudienceName = "";
    TemplateVersion = "";
    FullName = "";
    OnBehalfUserFullName = "";
    OnBehalfUser = "";
    PrimaryId = -1;
    SubRecords = subR;
    HasSubRecord = false;
    CampaignId = 0;
    ActiveSubscription = true;
    SubscriptionStatusMessage = "";
}

const FileDownload = require("js-file-download");
const theme = getTheme();
const { palette, semanticColors } = theme;


const stackTokens: Partial<IStackTokens> = { childrenGap: 0 };

const iconButtonStyles: Partial<IButtonStyles> = {
    root: { float: "right", height: "inherit" },
};

const iconButtonStylesPrompt = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },

};
const iconButtonNoteModalStyles = {
    root: {
        // color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },

};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const helpIcon: IIconProps = { iconName: "Help" };


const iconStylesWarn: IIconStyles = {
    root: {
        fontSize: "18px",
        height: "18px",
        width: "18px",
        color: "red",
    },
};
const iconExplorerStyles: IIconStyles = {
    root: {
        fontSize: "18px",
        height: "18px",
        width: "18px",
        color: "rgb(16, 110, 190)",
        paddingTop: "13px",
    },
};
const WarningIcon: IIconProps = { iconName: "Warning" };
let isAdvanceSearch = false;
type Props = {
    cacheAudience: (React.MutableRefObject<{
        id: string;
        isInAudience: boolean;
    }[]>)
    [key: string]: any;
}
function DataPanel({ cacheAudience, ...props }: Props) {

    const { branding } = useBranding()
    const { context } = useContext(AppContext)

    const docVersionList: IDocumentVersion[] = [];
    const [modalId, setModalId] = useState(-1);
    const [modalTitle, setModalTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [layout, setLayout] = useState("");
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const [spinnerText, setSpinnerText] = useState("");
    const [documentId, setDocumentId] = useState(-1);
    const [showVersion, setShowVersion] = useState(false);
    const [versionList, setversionList] = useState(docVersionList);
    const [showEmail, setShowEmail] = useState(false);
    const [documentDescription, setDocumentDescription] = useState("");
    const [username, setUsername] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState("");
    const [previewMime, setPreviewMime] = useState("");
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 639 ? true : false,
    });
    const sites: IChoiceGroupOption[] = [];
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [siteSelected, setSiteSelected] = useState("");
    const [siteOptions, setSiteOptions] = useState(sites);
    const [templateId, setTemplateId] = useState(-1);
    const [templateName, setTemplateName] = useState("");
    const [data, setData] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [documentAudienceOwner, setDocumentAudienceOwner] = useState("");
    const [showShare, setShowShare] = useState(false);
    const [showRenewals, setShowRenewals] = useState(false);

    const iconStyles: IIconStyles = {
        root: {
            fontSize: "18px",
            height: "18px",
            width: "18px",
            color: branding.theme.palette.themePrimary
        },
    };

    const [currentRenewalItem, setCurrentRenewalItem] = useState<IRecordRenewal>({
        TemplateId: 0,
        Name: "",
        Id: 0,
        RecordId: 0
    });

    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        containerSaveModal: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            width: isMobile ? null : "32vw",
            height: isMobile ? null : "20vh",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 0px 24px",
            },
        ],
        body: {
            width: isMobile ? null : "30vw",
            height: "15vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        bodyNote: {
            width: isMobile ? null : "25vw",
            height: "20vh",
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
        button: {
            width: 130,
        },
        callout: {
            width: 320,
            padding: "20px 24px",
        },
    });
    const classNames = mergeStyleSets({
        hideMenu: {
            display: "none",
        },
        showMenu: {
            display: "inline",
        },
        fileIconHeaderIcon: {
            padding: 0,
            fontSize: "16px",
        },
        fileIconCell: {
            textAlign: "center",
            selectors: {
                "&:before": {
                    content: ".",
                    display: "inline-block",
                    verticalAlign: "middle",
                    height: "100%",
                    width: "0px",
                    visibility: "hidden",
                },
            },
        },
        fileIconImg: {
            verticalAlign: "middle",
            maxHeight: "16px",
            maxWidth: "16px",
            color: "rgb(96, 94, 92)",
        },
        controlWrapper: {
            display: "flex",
            flexWrap: "wrap",
        },
        exampleToggle: {
            display: "inline-block",
            marginBottom: "10px",
            marginRight: "30px",
        },
        selectionDetails: {
            marginBottom: "20px",
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                color: theme.palette.black,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 0px 24px",
            },
        ],
        exampleRoot: {
            backgroundColor: "white",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "12px",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            selectors: {
                "&:hover": { background: palette.neutralLight },
            },
        },
        nameText: {
            fontWeight: "bold",
        },
    });

    const [showAttachment, setShowAttachment] = useState(false);
    const [showExecuted, setShowExecuted] = useState(false);
    const [Currentcolumn, setCurrentcolumn] = useState<IDocument>();
    const [showActivity, setshowActivity] = useState(false);
    const [showAddNote, setShowAddNote] = useState(false);
    const [note, setNote] = useState("");
    const [requiredNote, setRequiredNote] = useState(false);
    const [actionTitle, setActionTitle] = useState("");
    const [actionTitleButton, setActionTitleButton] = useState("");
    const [showNotes, setShowNotes] = useState(false);
    const [showSigners, setShowSigners] = useState(false);
    const [showUploadDoc, setShowUploadDoc] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [documentVersion, setDocumentVersion] = useState("");

    const targetButtonG = React.useRef<HTMLDivElement>(null);
    const targetButtonS = React.useRef<HTMLDivElement>(null);
    const [
        isCoachmarkVisibleG,
        { setFalse: hideCoachmarkG, setTrue: showCoachmarkG },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleS,
        { setFalse: hideCoachmarkS, setTrue: showCoachmarkS },
    ] = useBoolean(false);
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] =
        useBoolean(false);
    const [propertyBag, setPropertyBag] = useState<IPropertyBag>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [downloadDocX, setDownloadDocX] = useState(false);
    const [docSelected, setDocSelected] = useState<IDocument>();
    const [owner, setIsOwner] = useState(false);
    const [coowner, setIsCoOwner] = useState(false);
    const [ownerName, setOwnerName] = useState("");
    const [ownerUser, setOwnerUser] = useState("");
    const [templateCategory, setTemplateCategory] = useState("");
    const [audienceName, setAudienceName] = useState("");
    const [audienceMasterId, setAudienceMasterId] = useState("");

    const [showFormLink, setshowFormLink] = useState(false);
    const [showModalClone, setShowModalClone] = useState(false);

    const [shortcut, setShortcut] = useState("");

    const [showRemove, setShowRemove] = useState(false);
    const [removeeMessage, setremoveMessage] = useState("");
    const [actionText, setActionText] = useState("");
    const [version, setVersion] = useState(0);
    const [emailMoniker, setEmailMoniker] = useState("");
    const [allowDocx, setAllowDocx] = useState(false);
    const [documentCreation, setDocumentCreation] = useState(false);
    const [documentUpdate, setDocumentUpdate] = useState(false);
    const [documentDelete, setDocumentDelete] = useState(false);
    const [documentShare, setDocumentShare] = useState(false);
    const [templateEdit, setTemplateEdit] = useState(false);
    const [culture, setCulture] = useState("");
    const [templateVersion, setTemplateVersion] = useState("");

    const [iscoOwner, setiscoOwner] = useState(false);
    const [isCreator, setisCreator] = useState(false);
    const [isOwner, setisOwner] = useState(false);
    const [isSubform, setIsSubform] = useState(false);
    const [isModalSubform, setIsModalSubform] = useState(false);

    const subrecordsList: ISubRecord[] = [];
    const [subrecords, setSubrecords] = useState(subrecordsList);
    const [showFormTemplateLink, setshowFormTemplateLink] = useState(false);
    const [documentOnlyAudienceId, setDocumentOnlyAudienceId] = useState("");
    const [showDownloadConfirmationModal, setShowDownloadConfirmationModal] =
        useState(false);
    const [downloadType, setDownloadType] = useState("xml");
    const [downloadDocSelected, setDownloadDocSelected] = useState<IDocument>();
    const [downloadModalTitle, setDownloadModalTitle] = useState("");
    const [downloadModalText, setDownloadModalText] = useState("");
    const [showCampaignList, SetShowCampaignList] = useState(false);
    const [campaignId, setCampaignId] = useState(0);

    const [activeSubscription, setActiveSubscription] = useState(false);
    const [subscriptionStatusMessage, setSubscriptionStatusMessage] =
        useState("");

    const [isDisabled, setIsDisabled] = useState(true);
    const [
        isprimaryDocumentIdForSecondary,
        setIsprimaryDocumentIdForSecondary,
    ] = useState(false);
    const [
        isCalloutVisibleSubscription,
        { toggle: toggleIsCalloutVisibleSubscription },
    ] = useBoolean(false);
    const [draftId, setDraftId] = useState<number>(-1);
    const [isNoAllowSign, setisNoAllowSign] = useState(false);
    const [message, setMessage] = useState("");
    const [
        isIntegrationTestModalOpen,
        {
            setTrue: showIntegrationTestModal,
            setFalse: hideIntegrationTestModal,
        },
    ] = useBoolean(false);
    const [modalContent, setModalContent] = useState<any>(null);
    const { t } = useTranslation([
        "common",
        "workspace",
        "coachmarks",
        "recordnotes",
        "recordactivity",
        "recordcopy",
        "recorddelete",
        "preview",
        "recordmanage",
        "checkinout",
    ]);
    const [manualOutboundIntegrations, setManualOutboundIntegrations] =
        useState<IContextualMenuItem[]>([]);
    const [defaultDescription, setDefaultDescription] = useState("");
    const [isRecordUpdate, setIsRecordUpdate] = useState(false);
    const [isRecordError, setIsRecordError] = useState(false);

    const QueueCustomModal = useModal()

    const dialogSubscriptionContentProps = {
        type: DialogType.largeHeader,
        title:
            sessionStorage.getItem("entityId") !== "Not In Teams"
                ? "Information"
                : "Subscription",
        subText:
            props.docSelected.SubscriptionStatusMessage ??
            "Audience subcription disabled",
    };
    const modalProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
    };

    function showNotification(Nmessage: string, Ntype: string) {
        var auxType: ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: t("title.success", { ns: "common" }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "warning":
                store.addNotification({
                    title: t("title.warning", { ns: "common" }),
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "error":
                store.addNotification({
                    title: t("title.error", { ns: "common" }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "info":
                store.addNotification({
                    title: t("title.information", { ns: "common" }),
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: t("title.success", { ns: "common" }),
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: t("title.error", { ns: "common" }),
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (
            props.selectionState.getSelectedIndices().length >= 1 &&
            props.docSelected.Id !== -1
        ) {
            loadSubforms(props.docSelected);
            loadIntegrationsEvents(props.docSelected);
        }

        setGlobalDateLocale(navigator.language);
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addListener(setMQuery);

        return () => mediaQuery.removeListener(setMQuery);
    }, [props.docSelected.Id]);

    const toggleCustomForm = useConstCallback((open: boolean) => {
        const site = Helper.getSiteSelected();
        setSiteSelected(site);

        if (open === true) {
            props.onUpdateInterval(false);
            setShowCustomForm(open);
        } else {
            props.onUpdateInterval(true);
            setShowCustomForm(open);
        }
    });

    const toggleQueueModal = useCallback((open: boolean, isUpdate: boolean, isError: boolean) => {
        if (open) {
            QueueCustomModal.open()
            setIsRecordUpdate(isUpdate)
            setIsRecordError(isError)
        }
    }, [QueueCustomModal])

    const isTicklerQueue = (tickler: string) => {
        if (tickler?.toLowerCase() === "pending create") {
            return true;
        }
        if (tickler?.toLowerCase() === "pending update") {
            return true;
        }
        return false
    }

    const getAllowDocX = (item: IDocument) => {
        var value = false;

        var propBag = atob(item.TemplatePropertyBag);
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        try {
            jsonBag = JSON.parse(propBag);
            value = jsonBag.AllowDocX;
        } catch (error) { }
        setAllowDocx(value);
    };

    const onItemPreview = (item: IDocument) => {
        const id = item.Id;
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(t("title.modal", { ns: "preview" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setDocumentDescription(`${item.Description}`);
        setShowPreview(true);

        const preview = async () => {
            setAllowDocx(false);

            var jsonBag: IPropertyBag = {
                Clon: "",
                Culture: "",
                CultureName: "",
                Released: false,
                emailMoniker: "",
                AllowDocX: false,
                AllowSendMail: false,
                DocumentCreation: false,
                DocumentUpdate: false,
                DocumentDelete: false,
                DocumentShare: false,
                TemplateEdit: false,
                TemplateShare: false,
                TemplateDelete: false,
                DefaultDescription: "",
                attachment: {} as AttachmentSettings,
                SupressPreview: false,
                DocumentDelivery: "None",
                RedirectURL: ""
            };

            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
            };

            await Helper.getDocumentOnlyTemplateId(item.TemplateId.toString(), true)
                .then((data) => {
                    try {
                        jsonBag = JSON.parse(data.PropertyBag) as IPropertyBag;
                        setAllowDocx(jsonBag.AllowDocX);

                        Helper.getDocumentPreview(req).then((res) => {
                            if (res.Success) {
                                setDocumentId(item.Id);
                                setLayoutLoaded(true);
                                setPreviewURL(res.UrlPath);
                                setPreviewMime(res.mimeType);
                            }
                        });
                    } catch (reason) {
                        console.log(reason);
                    }
                })
                .catch((reason) => {
                    console.log(reason);
                })
                .finally(() => { });
        };
        preview();
    };

    const hidePreview = () => {
        setShowPreview(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        _deselect();
    };

    const onItemModalSubform = (item: IDocument) => {
        const id = item.Id;
        setSubrecords(item.SubRecords);
        setLayoutLoaded(false);
        setModalTitle(
            t("title.subrecordsModal", {
                ns: "workspace",
                Name: item!.Name,
                Category: item!.Category,
                Description: item!.Description,
                interpolation: { 'escapeValue': false }
            })
        );
        setDocumentId(id);
        setIsModalSubform(true);
        setSpinnerText(t("text.spinner", { ns: "common" }));
    };
    const hideModalSubform = () => {
        setIsModalSubform(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
    };

    const onItemDownload = (item: IDocument, isDocx: boolean) => {
        setShowConfirmationModal(false);
        const id = item.Id;
        const download = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: isDocx,
            };
            var res = await Helper.getDocumentDownload(req);

            if (res.Success === undefined || !res.Success) {
                const name = isDocx
                    ? `${res.Description}.docx`
                    : `${res.Description}.pdf`;
                const decoded = decode(res.Content);

                try {
                    const file = new Blob([decoded], { type: res.MimeType });
                    FileDownload(file, name);

                    showNotification(
                        t("message.documentDownload", { ns: "workspace" }),
                        "success"
                    );
                } catch (error) {
                    console.log(error);
                    showNotification(
                        t("message.error", { ns: "common", Error: error }),
                        "error"
                    );
                }
            }
        };
        download();
    };

    const onItemInvoked = (item: IDocument) => {
        if (!allowUpdate(item)) {
            return;
        }

        if (item.IsCustom) {
            const id = item.Id;
            const getData = async () => {
                const req: IDocumentRequest = {
                    UserName: Helper.getUsername(),
                    SessionKey: Helper.getSessionKey(),
                    AccountId: Helper.getDefaultAccount(),
                    DocumentId: "-1",
                    Id: id.toString(),
                    IsPreview: false,
                    IsDownload: true,
                    IsDocx: true,
                };
                var res = await Helper.getDocumentDownload(req);

                if (res.Success === undefined || !res.Success) {
                    setTemplateId(item.TemplateId);
                    setTemplateName(item.Name);
                    setTemplateDescription(item.Description);
                    setTemplateVersion(item.TemplateVersion);
                    setData(res.Data);
                    setIsSubform(false);
                    setDocumentId(item.Id);
                    setisOwner(item.Owner);
                    setiscoOwner(item.CoOwner);
                    setDocumentDescription(item.Description);
                    setAudienceName(item.AudienceName);
                    var emailMoniker = "";
                    var culture = "";
                    var jsonBag: IPropertyBag = {
                        Clon: "",
                        Culture: "",
                        CultureName: "",
                        Released: false,
                        emailMoniker: "",
                        AllowDocX: false,
                        AllowSendMail: false,
                        DocumentCreation: false,
                        DocumentUpdate: false,
                        DocumentDelete: false,
                        DocumentShare: false,
                        TemplateEdit: false,
                        TemplateShare: false,
                        TemplateDelete: false,
                        DefaultDescription: "",
                        attachment: {} as AttachmentSettings,
                        SupressPreview: false,
                        DocumentDelivery: "None",
                        RedirectURL: ""
                    };

                    if (item.TemplatePropertyBag != null) {
                        var propBag = atob(item.TemplatePropertyBag);
                        try {
                            jsonBag = JSON.parse(propBag);
                            culture = jsonBag.Culture;
                            emailMoniker = jsonBag.emailMoniker;
                            setDocumentUpdate(jsonBag.DocumentUpdate)
                        } catch (error) { }
                    }
                    setAudienceMasterId(await getAudienceMasterId(item!.TemplateId?.toString()))
                    setEmailMoniker(emailMoniker);
                    setOwnerUser(item!.UserName!);
                    setTemplateCategory(item!.Category!);
                    setCulture(culture);
                    toggleCustomForm(true);
                }
            };

            getData();
        } else {
            setLayout("");
            setLayoutLoaded(false);
            setSpinnerText(t("text.spinner", { ns: "common" }));

            const req: ITemplateLayoutRequest = {
                AccountId: Helper.getDefaultAccount(),
                TemplateId: item.TemplateId.toString(),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                DocumentId: item.Id.toString(),
            };

            Helper.getTemplateLayout(req).then((res) => {
                setLayout(res.Layout);
                setLayoutLoaded(true);
                setSpinnerText("");
            });

            setDocumentId(item.Id);
            setModalId(item.TemplateId);
            setModalTitle(item.Name);
            setShowModal(true);
        }
    };
    const onItemSubformInvoked = (item: any) => {
        var emailMoniker = "";
        var culture = "";
        var documenteDelete = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.PropertyBag != null) {
            var propBag = atob(item.PropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documenteDelete = jsonBag.DocumentDelete;
                culture = jsonBag.Culture;
                setDocumentCreation(jsonBag.DocumentCreation);
                setDocumentUpdate(jsonBag.DocumentUpdate)
            } catch (error) { }
        }

        setEmailMoniker(emailMoniker);
        setDocumentDelete(documenteDelete);
        setTemplateName(item!.DisplayName);
        setTemplateId(item!.TemplateId);
        setOwnerName(item!.OwnerFullName);
        setOwnerUser(item!.OwnerUserName);
        setTemplateCategory(item!.Category);
        setAudienceName(item!.AudienceName!);
        setiscoOwner(item!.CoOwner);
        setisCreator(item!.CreateDocument);
        setCulture(culture);
        setEmailMoniker(emailMoniker);
        setIsSubform(true);
        setData("");
        setIsprimaryDocumentIdForSecondary(true);
        toggleCustomForm(true);
    };

    const onItemStatusInvoked = (item: IDocument) => {
        setCurrentcolumn(item);
        setModalTitle(t("title.modal", { ns: "recordactivity" }));
        setDocumentDescription(`${item.Description}`);
        setshowActivity(true);
        setDocumentId(item.Id);
    };

    const onItemNotesInvoked = (item: IDocument) => {
        setDocumentId(item.Id);
        setCurrentcolumn(item);
        setModalTitle(t("title.modal", { ns: "recordnotes" }));
        setDocumentDescription(item.Description);
        setIsOwner(item.Owner);
        setIsCoOwner(item.CoOwner);
        setUsername(item.UserName);
        setShowNotes(true);
    };

    const onItemSignInvoked = (item: IDocument) => {
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setTemplateId(item.TemplateId);
        setDocumentName(item.Description);
        setDocumentVersion(item.Versions.toString());
        const GetDocument = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: item.Id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: true,
            };
            let res = await Helper.getDocumentDownload(req);
            if (
                (res.Success === undefined || !res.Success) &&
                res.allowesignDocumet
            ) {
                setModalTitle(t("title.modal", { ns: "recordsign" }));
                setShowSigners(true);
            } else {
                setModalTitle(
                    t("title.modalSignaturesNotFound", { ns: "recordsign" })
                );
                setLayoutLoaded(true);
                setisNoAllowSign(true);
                setMessage(
                    t("messages.documentNotDocusignTags", { ns: "recordsign" })
                );
            }
        };
        GetDocument();
    };

    const onItemClose = (item: IDocument) => {
        setDocumentId(item.Id);
        setTemplateId(item.TemplateId);
        if (!item.Closed) {
            setActionTitle(
                getRecordDescription(
                    t("title.btnClose", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.btnClose", { ns: "recordmanage" }));
            setModalId(1);
        } else {
            setActionTitle(
                getRecordDescription(
                    t("title.btnReOpen", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.btnReOpen", { ns: "recordmanage" }));
            setModalId(2);
        }
        setNote("");
        setShowAddNote(true);
    };

    const onItemRemove = (item: IDocument) => {
        setShowRemove(true);
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(
            getRecordDescription(
                t("title.modal", { ns: "recordremove" }),
                item.Description
            )
        );
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setremoveMessage(t("text.confirmRemove", { ns: "recordremove" }));
        setActionText(t("text.remove", { ns: "recordremove" }));
        setLayoutLoaded(true);
        setSpinnerText("");
        setShowRemove(true);
    };
    const onItemSuspended = (item: IDocument) => {
        setDocumentId(item.Id);
        if (!item.Suspended) {
            setActionTitle(
                getRecordDescription(
                    t("title.suspend", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.suspend", { ns: "recordmanage" }));
            setModalId(3);
        } else {
            setActionTitle(
                getRecordDescription(
                    t("title.resume", { ns: "recordmanage" }),
                    item.Description
                )
            );
            setActionTitleButton(t("title.resume", { ns: "recordmanage" }));
            setModalId(4);
        }
        setNote("");
        setShowAddNote(true);
    };

    const getDocumentOnlyDataPoint = useConstCallback(
        (item: IDocument, xmlPayload: boolean) => {
            const req: IDocumentOnlyDataPointRequest = {
                UserName: Helper.getUsername(),
                SK: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                ApiKey: "-1",
                Autoprovision: true,
                DocumentId: item.Id,
                XmlPayload: xmlPayload,
                Path: Helper.getSiteSelected(),
            };

            Helper.getDocumentOnlyDataPoint(req).then((data) => {
                try {
                    if (data?.Success == false) {
                        showNotification(
                            t("message.error", {
                                ns: "common",
                                Error: data.ExceptionMessage,
                            }),
                            "error"
                        );
                    } else if (
                        data.Success &&
                        data.ExceptionMessage.length > 0
                    ) {
                        showNotification(data.ExceptionMessage, "info");
                    } else {
                        const decoded = decode(data);
                        const fileName = `Data_${item.Name}.${xmlPayload ? "xml" : "xlsx"
                            }`;
                        const mimeType = xmlPayload
                            ? "application/xml"
                            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                        const file = new Blob([decoded], { type: mimeType });
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(file);
                        a.download = fileName;
                        a.click();
                        showNotification(
                            t("message.datapointsDownload", {
                                ns: "workspace",
                            }),
                            "success"
                        );
                    }
                } catch (error) {
                    console.log(error);
                    showNotification(
                        t("message.error", { ns: "common", Error: error }),
                        "error"
                    );
                }
                setShowDownloadConfirmationModal(false);
            });
        }
    );

    const getDocumentOnlyPackage = async (item: IDocument) => {
        const req: IDocumentOnlyPackageRequest = {
            UserName: Helper.getUsername(),
            SK: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            DocumentId: item.Id,
            Path: await Helper.getSite(),
        };

        Helper.getDocumentOnlyPackage(req).then((data) => {
            try {
                if (data?.Success == false) {
                    showNotification(
                        t("message.error", {
                            ns: "common",
                            Error: data.ExceptionMessage,
                        }),
                        "error"
                    );
                } else if (data.Success && data.ExceptionMessage.length > 0) {
                    showNotification(data.ExceptionMessage, "info");
                } else {
                    const decoded = decode(data.PackageBase64String);
                    const fileName = `Package_${data.FileName}`;
                    const mimeType = "application/zip";

                    const file = new Blob([decoded], { type: mimeType });
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();
                    showNotification(
                        t("message.successDownload", { ns: "workspace" }),
                        "success"
                    );
                }
            } catch (error) {
                console.log(error);
                showNotification(
                    t("message.error", { ns: "common", Error: error }),
                    "error"
                );
            }
            setShowDownloadConfirmationModal(false);
        });
    };

    const onItemDelete = (item: IDocument) => {
        setModalId(item.Id);
        setLayoutLoaded(false);
        setDocumentDescription(item.Description);
        setModalTitle(
            getRecordDescription(
                t("title.modal", { ns: "recorddelete" }),
                item.Description
            )
        );
        setSpinnerText(t("text.spinnerDeleting", { ns: "common" }));
        setDeleteId(item.Id.toString());
        setDeleteMessage(t("messages.delete", { ns: "recorddelete" }));
        setActionText(t("Common.Button.Delete", { ns: "common" }));
        setActionTitle(t("title.deleteButton", { ns: "recorddelete" }));
        Helper.getDocumentOnlyTemplateId(
            item.TemplateId.toString(), true,
            item.UserName
        ).then((data) => {
            setDocumentAudienceOwner(data.OwnerUserName);
        });

        var emailMoniker = "";
        var documenteDelete = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documenteDelete = jsonBag.DocumentDelete;
            } catch (error) { }
        }

        setEmailMoniker(emailMoniker);
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setTemplateName(item!.Name);
        setDocumentDelete(documenteDelete);
        setAudienceName(item!.AudienceName!);
        setTemplateId(item!.TemplateId);
        setLayoutLoaded(true);
        setShowConfirm(true);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setDeleteMessage("");

        setMessage("");
        setisNoAllowSign(false);
        //setLayoutLoaded(true);


    };

    /**
     * on Before Renewal Dismiss
     * @param update
     */
    const onBeforeDismiss = (update: boolean = false) => {
        setShowRenewals(false)
    }

    const confirmDelete = (id: string) => {

        setLayoutLoaded(false)

        const deleteDoc = async () => {
            const site = await Helper.getSite()!;
            const sessionKey = Helper.getSessionKey();
            const accountId = Helper.getDefaultAccount();
            const userName = Helper.getUsername();

            const req: IDeleteDocumentRequest = {
                UserName: userName,
                SessionKey: sessionKey,
                AccountId: accountId,
                DocumentId: id,
            };
            var res = await Helper.DeleteDocument(req);
            hideConfirm();
            if (res.Success) {
                props.update()
                context.setRefreshRenewals(true)

            } else {
                showNotification(
                    t("message.error", {
                        ns: "common",
                        Error: res.ExceptionMessage,
                    }),
                    "error"
                );
            }
        };
        deleteDoc();
    };

    const onItemVersions = async (item: IDocument) => {
        var ItemData = await GetDataDocument(item).then((x) => {
            setModalId(item.Id);
            setLayoutLoaded(false);
            setModalTitle("Version");
            setDocumentDescription(item.Description);
            setSpinnerText(t("text.spinner", { ns: "common" }));
            const req: IDocumentVersionRequest = {
                AccountId: Helper.getDefaultAccount(),
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                DocumentId: item.Id.toString(),
                Id: "",
                IsPreview: false,
                IsDownload: false,
                IsDocx: false,
            };

            Helper.getDocumentVersion(req).then(async (res) => {
                var auxList: IDocumentVersion[] = [];
                if (res != null && res.length > 0) {
                    res.forEach((e: any) => {
                        auxList.push(e);
                    });
                }
                setversionList(auxList);
                setLayoutLoaded(true);
                setSpinnerText("");
            });
            setShowVersion(true);
        });
    };

    const hideEmail = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setEmailMoniker("");
        setShowEmail(false);
        setDocumentDescription("");
        setDocumentId(-1);
    };

    const hideVersion = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setShowVersion(false);
    };
    const hideShare = () => {
        setDocumentId(-1);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setShowShare(false);
    };
    const hideAttachment = () => {
        setShowAttachment(false);
    };
    const hideExecuted = () => {
        setShowExecuted(false);
    };
    const onDocumentAttachment = (item: IDocument) => {
        setDocumentId(item.Id);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setShowAttachment(true);
        setDocumentDescription(`${item.Description}`);
        setTemplateId(item!.TemplateId);
        setActiveSubscription(item.ActiveSubscription);
        setSubscriptionStatusMessage(item.SubscriptionStatusMessage);
    };

    const onClone = (item: IDocument) => {
        const id = item.Id;
        const getData = async () => {
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id.toString(),
                IsPreview: false,
                IsDownload: true,
                IsDocx: true,
            };
            setDocumentId(item.Id);
            setTemplateId(item.TemplateId);
            setShortcut(item.Description);
            setTemplateVersion(item.TemplateVersion);
            setShowModalClone(true);
        };
        getData();
    };

    const onExecuted = (item: IDocument) => {
        setDocumentId(item.Id);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setShowExecuted(true);
        setModalTitle(t("title.modalExecuted", { ns: "recordsign" }));
        setDocumentDescription(`${item.Description}`);
    };


    const onItemSend = (item: IDocument) => {
        const user = Helper.getUsername();

        var emailMoniker = "";
        var allowDocx = false;
        var sendMail = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                console.log(jsonBag)
                emailMoniker = jsonBag.emailMoniker;
                allowDocx = jsonBag.AllowDocX;
                sendMail = jsonBag.AllowSendMail;
            } catch (error) { }
        }

        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle("New Mail");
        setSpinnerText("Loading email...");
        setEmailMoniker(emailMoniker);
        setAllowDocx(allowDocx);
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setTemplateId(item.TemplateId)
        setUsername(user);
        setLayoutLoaded(true);
        setSpinnerText("");
        setShowEmail(true);
    };

    const onItemShare = (item: IDocument) => {
        const user = Helper.getUsername();
        Helper.getDocumentOnlyTemplateId(
            item.TemplateId.toString(), true,
            user
        ).then((data) => {
            setDocumentAudienceOwner(data.OwnerUserName);
        });

        var emailMoniker = "";
        var documentShare = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            Released: false,
            emailMoniker: "",
            CultureName: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                documentShare = jsonBag.DocumentShare;
            } catch (error) { }
        }

        setPropertyBag(jsonBag);
        setModalId(item.Id);
        setLayoutLoaded(false);
        setModalTitle(t("title.modal", { ns: "recordshare" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setDocumentDescription(`${item.Description}`);
        setDocumentId(item.Id);
        setUsername(user);

        setEmailMoniker(emailMoniker);
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setTemplateName(item!.Name);
        setDocumentShare(documentShare);
        setAudienceName(item!.AudienceName!);
        setTemplateId(item!.TemplateId);
        setLayoutLoaded(true);
        setSpinnerText("");

        setShowShare(true);
    };

    const hideModal = () => {
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setLayout("");
        setModalTitle("");
        setShowModal(false);
    };
    const hideAddNoteModal = () => {
        setShowAddNote(false);
    };
    const hideModalClone = () => {
        setShowModalClone(false);
    };
    const hideModalActivity = () => {
        setshowActivity(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideModalNotes = () => {
        setShowNotes(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideModalSigners = () => {
        setShowSigners(false);
        setModalTitle("");
        setDocumentId(-1);
    };
    const hideConfirmationModal = () => {
        setShowConfirmationModal(false);
    };
    const getKey = (item: IDocument, index?: number): string => {
        if (item !== undefined) return item.Id.toString();

        return "";
    };

    const onItemVersionInvoked = (item: IDocument) => {
        if (item.Versions > 1) {
            if (allowAdminAccess()) {
                getAllowDocX(item);
                onItemVersions(item);
                _deselect();
            }
        }
    };

    const confirmRemove = (id: string) => {
        const RemoveDoc = async () => {

            const request = {
                DocumentId: id,
                TargetUserName: Helper.getUsername()
            }

            let { data }: any = await HttpRequest.getWithSession('DeleteAllowedUser', request)
            const response = data

            if (response !== undefined) {
                if (response.Success) {
                    props.update();
                }
            }

            setSpinnerText("");
            hideRemove();
        };
        RemoveDoc();
    };
    const hideRemove = () => {
        setShowRemove(false);
        setLayoutLoaded(false);
        setSpinnerText(t("text.spinner", { ns: "common" }));
        setModalId(-1);
        setModalTitle("");
        setremoveMessage("");
        setActionText("");
        setLayoutLoaded(false);
    };

    const getRecordDateTitle = (item: IDocument) => {
        var result;

        item.DateCreated !== null
            ? (result =
                t("text.created", { ns: "workspace" }) +
                Helper.getLocateDate(item.DateCreated, 2))
            : (result = "N/A");
        item.DateUpdated !== null
            ? (result +=
                t("text.updated", { ns: "workspace" }) +
                Helper.getLocateDate(item.DateCreated, 2))
            : (result = result);

        return result;
    };

    const getRecordDescriptionHTMLTemplate = (item: IDocument) => {

        return (
            <>
                <Stack>
                    {item.Tickler !== null &&
                        item.Tickler.trim().length > 0 &&
                        <StackItem>

                            <Text block variant={"small"}
                                styles={{ root: { color: "#FF0000", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }}
                            >
                                {item.Tickler}
                            </Text>
                        </StackItem>
                    }

                    <Text block={true} variant={"mediumPlus"} styles={{
                        root: {
                            fontWeight: "500",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            color: item.Tickler !== null &&
                                item.Tickler.trim().length > 0 && item.Tickler.includes("New") ? branding.theme.palette.themePrimary : "default"
                        }
                    }}
                        title={t("title.subject", {
                            ns: "workspace",
                            Description: item.Description,
                            interpolation: { escapeValue: false }
                        })}>{item.Description}
                    </Text>


                    <Text block={true} variant={"medium"}
                        styles={{ root: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
                        title={t("title.applicationName", {
                            ns: "workspace",
                            Name: item.Name,
                            Category: item.Category,
                            interpolation: { escapeValue: false }
                        })}
                    >{`${item.Category} - ${item.Name}`}</Text>
                    <Text block={true} variant={"medium"} styles={{
                        root: {
                            fontWeight: "300",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }
                    }}
                        title={
                            t("title.origen", {
                                interpolation: { escapeValue: false },
                                ns: "workspace", Name:
                                    item.OnBehalfUser === null || item.OnBehalfUser.trim().length === 0 ?
                                        item.FullName !== null && item.FullName.trim().length !== 0 && item.FullName.toLowerCase().trim() !== item.UserName.toLowerCase().trim() ? `${item.FullName} <${item.UserName}>` : item.UserName
                                        :
                                        item.OnBehalfUserFullName !== null && item.OnBehalfUserFullName.trim().length !== 0 && item.OnBehalfUserFullName.toLowerCase().trim() !== item.OnBehalfUser.toLowerCase().trim() ? `${item.OnBehalfUserFullName} <${item.OnBehalfUser}>` : item.OnBehalfUser
                            })
                        }
                    >
                        {item.OnBehalfUser === null || item.OnBehalfUser.trim().length === 0 ?
                            item.FullName !== null && item.FullName.trim().length !== 0 && item.FullName.toLowerCase().trim() !== item.UserName.toLowerCase().trim() ? item.FullName : item.UserName
                            :
                            item.OnBehalfUserFullName !== null && item.OnBehalfUserFullName.trim().length !== 0 && item.OnBehalfUserFullName.toLowerCase().trim() !== item.OnBehalfUser.toLowerCase().trim() ? item.OnBehalfUserFullName : item.OnBehalfUser
                        }
                    </Text>

                    <StackItem>
                        <Text block
                            variant={"small"}
                            title={getRecordDateTitle(item)}
                            styles={{ root: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } }}
                        >
                            {item.DateUpdated !== null
                                ? Helper.getLocateDate(item.DateUpdated, 3)
                                : item.DateCreated !== null
                                    ? Helper.getLocateDate(item.DateCreated, 3)
                                    : null}

                        </Text>
                    </StackItem>


                </Stack>
            </>
        )

    };

    const getParsedActivity = (item: IDocument) => {
        var value = item.LastActivity;
        if (item.LastActivity !== null) {
            var location = item.LastActivity.toLowerCase().indexOf("'");
            if (location > -1) {
                value = item.LastActivity.substring(0, location);
            }
        }
        return value;
    };

    const getVersionControlCell = (item: IDocument) => {
        return (
            <Text
                style={{
                    cursor: (allowAdminAccessOnLoad(item) && item.Versions > 1) && !isTicklerQueue(item.Tickler) ? "pointer" : "default",
                    display: "block",
                    color: branding.theme.palette.themeDarker,
                }}
                onClick={(allowAdminAccessOnLoad(item) && item.Versions > 1) && !isTicklerQueue(item.Tickler) ? () => {
                    onItemVersionInvoked(item);
                } : undefined}
                title={
                    item.UserName === Helper.getUsername() &&
                        item.Versions > 1
                        ?
                        t("title.gridVersions", { ns: "workspace", count: item.Versions })
                        : t("title.gridColVersions", { ns: "workspace", count: item.Versions })
                }
            >

                {item.Versions}

            </Text>
        );

    };

    const allowAdminAccess = () => {
        if (
            props.docSelected.Owner ||
            props.docSelected.CoOwner ||
            props.docSelected.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            return false;
        }
    };
    const allowAdminAccessOnly = () => {
        if (props.docSelected.Owner || props.docSelected.CoOwner) {
            return true;
        } else {
            return false;
        }
    };
    const columns: IColumn[] = [
        {
            key: "column0",
            name: t("Common.Keys.Subrecords", { ns: "common" }),
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColSubrecordsIcon", { ns: "workspace" }),
            iconName: "NumberedList",
            isIconOnly: true,
            fieldName: "subrecord",
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IDocument) => {
                return item.HasSubRecord && !isTicklerQueue(item.Tickler) ? (
                    <Icon
                        style={{ cursor: "pointer" }}
                        title={t("title.gridSubrecordsIcon", {
                            ns: "workspace",
                        })}
                        onClick={() => {
                            onItemModalSubform(item);
                        }}
                        iconName={"ChevronDown"}
                    />
                ) : null;
            },
        },
        {
            key: "column1",
            name: "File Type",
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColDocumentIcon", { ns: "workspace" }),
            iconName: "pdf",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IDocument) => {
                return isMobile ? (
                    isTicklerQueue(item.Tickler) ? null :
                        <IconButton
                            iconProps={{ iconName: "pdf" }}
                            style={{ cursor: "pointer" }}
                            title={t("title.gridDocumentIcon", { ns: "workspace" })}
                            className={classNames.fileIconImg}
                            alt={t("title.gridDocumentIcon", { ns: "workspace" })}
                            onTouchEnd={(obj: any) => {
                                props.onDocumentSelected(item);
                                onItemPreview(item);
                                _deselect();
                            }}
                        />
                ) : (
                        isTicklerQueue(item.Tickler) ? null :
                            <IconButton
                                iconProps={{ iconName: "pdf" }}
                                style={{ cursor: "pointer" }}
                                title={t("title.gridDocumentIcon", { ns: "workspace" })}
                                className={classNames.fileIconImg}
                                alt={t("title.gridDocumentIcon", { ns: "workspace" })}
                                onClick={() => {
                                    props.onDocumentSelected(item);
                                    onItemPreview(item);
                                }}
                            />
                    );
            },
        },
        {
            key: "column2",
            name: t("Common.Keys.FormWizard", { ns: "common" }),
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: t("title.gridColFormIcon", { ns: "workspace" }),
            iconName: "officeFormsLogo",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IDocument) => {
                if (allowUpdateOnLoad(item)) {
                    setIsOwner(item!.Owner);
                    return (
                        <div
                            title={
                                item.ActiveSubscription
                                    ? ""
                                    : item.SubscriptionStatusMessage
                            }
                        >
                            {isTicklerQueue(item.Tickler) ? null :
                                <IconButton
                                    iconProps={{ iconName: "officeFormsLogo" }}
                                    hidden={isOptionDisabled(
                                        CheckoutOptionsVisibilityType.WebFormIcon,
                                        item
                                    )}
                                    disabled={
                                        !item.ActiveSubscription &&
                                        sessionStorage.getItem("billingActive") ===
                                        "true"
                                    }
                                    allowDisabledFocus
                                    style={{ cursor: "pointer" }}
                                    title={t("title.gridFormIcon", {
                                        ns: "workspace",
                                    })}
                                    className={classNames.fileIconImg}
                                    alt={t("title.gridFormIcon", {
                                        ns: "workspace",
                                    })}
                                    onClick={() => {
                                        props.onDocumentSelected(item);
                                        onItemInvoked(item);
                                        _deselect();
                                    }}
                                />}
                        </div>
                    );
                }
            },
        },
        {
            key: "column3",
            name: t("Common.Keys.Subject", { ns: "common" }),
            fieldName: "Description",
            minWidth: isMobile ? 160 : 280,
            maxWidth: isMobile ? 160 : 280,
            isResizable: true,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                return getRecordDescriptionHTMLTemplate(item);
            },
        },
        {
            key: "column4",
            name: t("Common.Keys.Activity", { ns: "common" }),
            fieldName: "LastActivity",
            minWidth: isMobile ? 0 : 100,
            maxWidth: isMobile ? 0 : 140,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            isPadded: false,
            onRender: (item: IDocument) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "start",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}
                               data-for="LastActivityToolTip"
                               data-tip={t("title.gridActivityIcon", {
                                   ns: "workspace",
                               })}
                               onClick={!isTicklerQueue(item.Tickler) ? () => {
                                   onItemStatusInvoked(item);
                               } : undefined}
                    >
                        <Text
                            styles={{
                                root: {
                                    cursor: !isTicklerQueue(item.Tickler) ? "pointer" : "default",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: branding.theme.palette.themeDarker,
                                }
                            }}
                            data-for="LastActivityToolTip"
                            data-tip={t("title.gridActivityIcon", {
                                ns: "workspace",
                            })}
                            onClick={!isTicklerQueue(item.Tickler) ? () => {
                                onItemStatusInvoked(item);
                            } : undefined}
                        >
                            {getParsedActivity(item)}<br/>
                            {item.CheckedOut
                                ? t("title.checkedOut", {ns: "workspace"})
                                : ""}
                        </Text>
                    </StackItem>
                );
            },
        },
        {
            key: "column5",
            name: t("Common.Keys.Note", { ns: "common" }),
            fieldName: "Note",
            minWidth: isMobile ? 0 : 120,
            maxWidth: isMobile ? 0 : 800,
            isCollapsible: true,
            isMultiline: true,
            isResizable: true,
            onRender: (item: IDocument) => {
                return (
                    <Stack styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",

                        }
                    }}
                        onClick={!isTicklerQueue(item.Tickler) ? () => {
                            onItemNotesInvoked(item);
                        } : undefined}
                        data-for="NoteToolTip"
                        data-tip={
                            item.LastNote !== null
                                ? t("title.gridNoteIcon1", {
                                    ns: "workspace",
                                })
                                : t("title.gridNoteIcon2", {
                                    ns: "workspace",
                                })
                        }
                    >
                        <Text
                            id="titleNotes"
                            styles={{
                                root: {
                                    cursor: !isTicklerQueue(item.Tickler) ? "pointer" : "default",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: branding.theme.palette.themeDarker,
                                    width: "100%",
                                }
                            }}
                            data-for="NoteToolTip"
                            data-tip={
                                item.LastNote !== null
                                    ? t("title.gridNoteIcon1", {
                                        ns: "workspace",
                                    })
                                    : t("title.gridNoteIcon2", {
                                        ns: "workspace",
                                    })
                            }
                            onClick={!isTicklerQueue(item.Tickler) ? () => {
                                onItemNotesInvoked(item);
                            } : undefined}

                        >
                            {item.LastNote !== null ? item.LastNote : " "}
                        </Text>                       
                    </Stack>
                );
            },
            data: "string",
            isPadded: false,
        },
        {
            key: "column6",
            name: t("Common.Keys.Attachments", { ns: "common" }),
            fieldName: "Attachments",
            minWidth: isMobile ? 0 : 70,
            maxWidth: isMobile ? 0 : 100,
            isResizable: false,
            isCollapsible: true,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        <Text
                            id="titleNotes"
                            styles={{
                                root: {
                                    cursor: !isTicklerQueue(item.Tickler) ? "pointer" : "default",
                                    color: branding.theme.palette.themeDarker,
                                }
                            }}
                            onClick={!isTicklerQueue(item.Tickler) ? () => {
                                onDocumentAttachment(item);
                            } : undefined}


                            title={
                                item.UserName === Helper.getUsername() &&
                                    item.Versions > 1
                                    ? item.Attachments.toString() +
                                    t("title.gridAttachment", { ns: "workspace" })
                                    : item.Attachments.toString() +
                                    " " +
                                    t("title.gridAttachment", { ns: "workspace" })
                            }
                        >

                            {item.Attachments}
                        </Text>
                    </StackItem>
                );
            },
        },
        {
            key: "column7",
            name: t("Common.Keys.Version", { ns: "common" }),
            fieldName: "Versions",
            minWidth: isMobile ? 0 : 50,
            maxWidth: isMobile ? 0 : 50,
            isResizable: false,
            isCollapsible: true,
            data: "number",
            isPadded: true,
            onRender: (item: IDocument) => {
                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        {getVersionControlCell(item)}
                    </StackItem>
                )
            },
        },
        {
            key: "column8",
            name: t("Common.Keys.Action", { ns: "common" }),
            fieldName: "Options",
            minWidth: isMobile ? 20 : 40,
            maxWidth: isMobile ? 20 : 40,
            isResizable: false,
            onRender: (item: IDocument) => {

                return (
                    <StackItem styles={{
                        root: {
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%"
                        }
                    }}>
                        {isTicklerQueue(item.Tickler) ? null :
                            <IconButton
                                menuIconProps={{
                                    iconName: "MoreVertical",
                                    style: {
                                        fontSize: 20,
                                        color: "#242424",
                                        textAlign: "right",
                                    },
                                }}
                                role="button"
                                aria-haspopup
                                aria-label={t("title.gridColOptions", {
                                    ns: "workspace",
                                })}
                                styles={iconButtonStyles}
                                menuProps={
                                    isMobile &&
                                        sessionStorage.getItem("entityId") !== "Not In Teams" ?
                                        item.Closed == true ? menuPropsMobileClosed : menuPropsMobile
                                        :
                                        item.Closed == true ? menuPropsClosed : menuProps
                                }
                                title={t("title.gridOptions", {
                                    ns: "workspace",
                                })}
                            />}

                    </StackItem>
                )


            },
            isPadded: true,
        },
    ];

    const disabledCheckIn = (): boolean => {
        if (props.docSelected.CheckedOut) {
            return false;
        } else {
            return true;
        }
    };

    const setToggleUploadDoc = (state: boolean) => {
        setShowUploadDoc(state);
    };

    const allowAdminAccessOnLoad = (item: IDocument) => {
        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getAllowSendMail = (item: IDocument) => {
        var allowSendMail = false;
        if (
            item.TemplatePropertyBag != "" &&
            item.TemplatePropertyBag !== null
        ) {
            var propBag = atob(item.TemplatePropertyBag);
            var jsonBag = JSON.parse(propBag);
            allowSendMail = jsonBag.AllowSendMail;
        }

        if (
            props.docSelected.Owner ||
            props.docSelected.CoOwner ||
            props.docSelected.UserName === Helper.getUsername() ||
            allowSendMail
        ) {
            return true;
        } else {
            return false;
        }
    };

    const allowUpdateOnLoad = (item: IDocument) => {
        if (item.Suspended || item.Closed || item.CheckedOut) {
            return false;
        }

        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            if (item.AllowDocumentUpdate) {
                return true;
            } else {
                return false;
            }
        }
    };

    const allowUpdate = (item: IDocument) => {
        if (
            item.Owner ||
            item.CoOwner ||
            item.UserName === Helper.getUsername()
        ) {
            return true;
        } else {
            if (item.AllowDocumentUpdate) {
                return true;
            } else {
                return false;
            }
        }
    };

    const isOptionDisabled = (
        menuOption: CheckoutOptionsVisibilityType,
        item: IDocument
    ) => {
        switch (menuOption) {
            case CheckoutOptionsVisibilityType.Checkin: {
                if (
                    item.CheckedOut &&
                    item.CheckedOutUser == Helper.getUsername()
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.Checkout: {
                if (allowAdminAccess() && item.CheckedOut == false) {
                    return false;
                } else if (
                    allowAdminAccess() == false &&
                    item.CheckedOut == false
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.Download: {
                if (
                    item.CheckedOut &&
                    item.CheckedOutUser == Helper.getUsername()
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.UndoCheckout: {
                if (
                    (allowAdminAccess() && item.CheckedOut) ||
                    (item.CheckedOut &&
                        item.CheckedOutUser == Helper.getUsername())
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            case CheckoutOptionsVisibilityType.WebFormIcon: {
                if (item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.Manage: {
                if (item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.Delete: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.WebformLink: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            case CheckoutOptionsVisibilityType.UpdateWebForm: {
                if (item.Suspended || item.Closed || item.CheckedOut) {
                    return true;
                } else {
                    return false;
                }
            }
            default:
                return false;
        }
    };

    const userBelongsToTemplateAudiece = () => {
        let templates = props.templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key == props.docSelected.TemplateId.toString()
        );
        if (template == undefined) return false;

        const fromCache = cacheAudience?.current.find(a => a.id === template.DocumentOnlyAudienceId)
        if (fromCache) return fromCache.isInAudience
        const req: IDocumentOnlyAudienceRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: Helper.getSiteSelected(),
            AudienceMasterId: template.DocumentOnlyAudienceId,
            FromRegistration: false
        };
        return Helper.GetDocumentOnlyAudienceDetailByAudienceMasterId(req).then(
            (data) => {
                const isInAudience = (data as IDocumentOnlyAudienceDetail[]).find((item) => item.UserName == Helper.getUsername())
                if (cacheAudience?.current) {
                    cacheAudience.current = [...cacheAudience?.current, { id: template.DocumentOnlyAudienceId, isInAudience: !!isInAudience }]
                } else {
                    cacheAudience.current = [{ id: template.DocumentOnlyAudienceId, isInAudience: !!isInAudience }]
                }
                return !!isInAudience
            }
        );

        // AudienceService.userBelongsToTemplateAudiece(template.DocumentOnlyAudienceId).then(({data}:any)=>{
        //     const isInAudience = (data as IDocumentOnlyAudienceDetail[]).find((item) => item.UserName == Helper.getUsername())
        //     cacheAudience.current = [...cacheAudience?.current, {id:template.DocumentOnlyAudienceId,isInAudience:!!isInAudience}]
        //     return !!isInAudience 
        // })
    };

    const getAudienceMasterId = async (templateId: string) => {
        let templates = props.templateOnlyList.reduce(
            (acc: any, el: any) => acc.concat(el.links),
            []
        );
        let template = templates.find(
            (item: any) => item.key === templateId
        );
        if (template === undefined) {
            const templateResponse = await Helper.getDocumentOnlyTemplateId(templateId, true)
            return templateResponse?.DocumentOnlyAudienceId !== undefined ? templateResponse.DocumentOnlyAudienceId.toString() : ""

        }
        return template.DocumentOnlyAudienceId.toString();
    };

    const downloadDocumentOnlyDataPointCallback = useConstCallback(
        (document: IDocument, type: string) => {
            if (type == "pack") {
                getDocumentOnlyPackage(document);
            } else {
                getDocumentOnlyDataPoint(
                    document,
                    type == "xml" ? true : false
                );
            }
        }
    );

    const menuItems: IContextualMenuItem[] = [
        {
            key: "activity",
            text: t("title.activityText", { ns: "workspace" }),
            title: t("title.activityTitle", { ns: "workspace" }),
            className: "activityMenuActions",
            onClick: () => {
                onItemStatusInvoked(props.docSelected);
                _deselect();
            },
        },
        {
            key: "attachment",
            text: t("title.attachmentText", { ns: "workspace" }),
            title: t("title.attachmentTitle", { ns: "workspace" }),
            onClick: () => {
                onDocumentAttachment(props.docSelected);
                _deselect();
            },
        },
        {
            key: "campaign",
            text: t("title.campaignText", { ns: "workspace" }),
            title: t("title.campaignTitle", { ns: "workspace" }),
            onClick: () => {
                onCampaignItem(props.docSelected);
                _deselect();
            },
            className: classNames.hideMenu,
        },
        {
            key: "checkout",
            text: t("title.checkoutText", { ns: "workspace" }),
            title: t("title.checkoutTitle", { ns: "workspace" }),
            onClick: () => {
                if (props.docSelected.CheckedOut == false) {
                    setDownloadDocX(true);
                    setDocSelected(props.docSelected);
                    setShowConfirmationModal(true);
                    setDownloadModalTitle(
                        t("title.modalCheckout", { ns: "checkinout" })
                    );
                    setDownloadModalText(
                        t("text.checkout", { ns: "checkinout" })
                    );
                }
            },
            subMenuProps:
                props.docSelected.CheckedOut == false
                    ? undefined
                    : {
                        items: [
                            {
                                key: "checkin",
                                text: t("title.checkinText", {
                                    ns: "workspace",
                                }),
                                title: t("title.checkintTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    setShowUploadDoc(true);
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.Checkin,
                                    props.docSelected
                                ),
                            },
                            {
                                key: "checkout",
                                text: t("title.checkoutText", {
                                    ns: "workspace",
                                }),
                                title: t("title.checkoutTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    checkIncheckOut(true);
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.Checkout,
                                    props.docSelected
                                ),
                            },
                            {
                                key: "download",
                                text: t("title.downloadText", {
                                    ns: "workspace",
                                }),
                                title: t("title.downloadTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    setDownloadDocX(true);
                                    setDocSelected(props.docSelected);
                                    setShowConfirmationModal(true);
                                    setDownloadModalTitle(
                                        t("title.modalDownload", {
                                            ns: "checkinout",
                                        })
                                    );
                                    setDownloadModalText(
                                        t("text.download", {
                                            ns: "checkinout",
                                        })
                                    );
                                    _deselect();
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.Download,
                                    props.docSelected
                                ),
                            },
                            {
                                key: "undoCheckout",
                                text: t("title.undoCheckoutText", {
                                    ns: "workspace",
                                }),
                                title: t("title.undoCheckoutTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    UndoCheckOut(true);
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.UndoCheckout,
                                    props.docSelected
                                ),
                            },
                        ],
                    },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className: /*props.docSelected.AllowCheckInOut && allowAdminAccess() ? classNames.showMenu : */classNames.hideMenu,
        },
        {
            key: "clone",
            text: t("title.cloneText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.cloneTitle", { ns: "workspace" }),
            onClick: () => {
                onClone(props.docSelected);
                _deselect();
            },
            disabled:
                !props.docSelected.ActiveSubscription &&
                sessionStorage.getItem("billingActive") === "true",
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "delete",
            text: t("title.deleteText", { ns: "workspace" }),
            title: t("title.deleteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemDelete(props.docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Delete,
                props.docSelected
            ),
            className:
                allowAdminAccess() && disabledCheckIn()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "download",
            text: t("title.downloadText2", { ns: "workspace" }),
            title: t("title.downloadTitle2", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "datapoints",
                        text: t("title.datapointsText", { ns: "workspace" }),
                        subMenuProps: {
                            items: [
                                {
                                    key: "xml",
                                    text: "XML",
                                    onClick: () => {
                                        setShowDownloadConfirmationModal(true);
                                        setDownloadType("xml");
                                        setDownloadDocSelected(
                                            props.docSelected
                                        );
                                    },
                                },
                                {
                                    key: "xlsx",
                                    text: "XLSX",
                                    onClick: () => {
                                        setShowDownloadConfirmationModal(true);
                                        setDownloadDocSelected(
                                            props.docSelected
                                        );
                                        setDownloadType("xlsx");
                                    },
                                },
                            ],
                        },
                    },
                    {
                        key: "package",
                        text: t("title.packageText", { ns: "workspace" }),
                        onClick: () => {
                            setDownloadType("pack");
                            setShowDownloadConfirmationModal(true);
                            setDownloadDocSelected(props.docSelected);
                        },
                    },
                ],
            },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className:
                userBelongsToTemplateAudiece() && allowAdminAccess()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "Integrations",
            text: t("title.integrations", { ns: "workspace" }),
            title: t("title.integrations", { ns: "workspace" }),
            className:
                manualOutboundIntegrations.length > 0
                    ? classNames.showMenu
                    : classNames.hideMenu,
            subMenuProps: {
                items: manualOutboundIntegrations,
            },
        },
        {
            key: "manage",
            text: t("title.manageText", { ns: "workspace" }),
            title: t("title.manageTitle", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "close",
                        text: props.docSelected.Closed
                            ? t("title.reOpenText", { ns: "workspace" })
                            : t("title.closeText", { ns: "workspace" }),
                        title: props.docSelected.Closed
                            ? t("title.reOpenTitle", { ns: "workspace" })
                            : t("title.closeTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemClose(props.docSelected);
                            _deselect();
                        },
                    },
                    {
                        key: "suspend",
                        text: props.docSelected.Suspended
                            ? t("title.resumeText", { ns: "workspace" })
                            : t("title.suspendText", { ns: "workspace" }),
                        title: props.docSelected.Suspended
                            ? t("title.resumeTitle", { ns: "workspace" })
                            : t("title.suspendTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemSuspended(props.docSelected);
                            _deselect();
                        },
                    },
                ],
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Manage,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "note",
            text: t("title.noteText", { ns: "workspace" }),
            title: t("title.noteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemNotesInvoked(props.docSelected);
                _deselect();
            },
        },
        {
            key: "preview",
            text: t("title.previewText", { ns: "workspace" }),
            title: t("title.previewTitle", { ns: "workspace" }),
            onClick: () => {
                onItemPreview(props.docSelected);
                _deselect();
            },
        },
        {
            key: "Remove",
            text: t("title.removeText", { ns: "workspace" }),
            title: t("title.removeTitle", { ns: "workspace" }),
            onClick: () => {
                onItemRemove(props.docSelected);
                _deselect();
            },
            className:
                props.docSelected.UserName === Helper.getUsername()
                    ? classNames.hideMenu
                    : classNames.showMenu,
        },
        {
            key: "Renewals",
            text: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            title: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            onClick: () => {

                setCurrentRenewalItem({
                    Name: props.docSelected.Description,
                    TemplateId: props.docSelected.TemplateId,
                    Id: 0,
                    RecordId: props.docSelected.Id
                })

                setShowRenewals(true)
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendMail",
            text: t("title.sendMailText", { ns: "workspace" }),
            title: t("title.sendMailTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSend(props.docSelected);
                _deselect();
            },
            className: getAllowSendMail(props.docSelected)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendLink",
            text: t("title.sendLinkText", { ns: "workspace" }),
            title: t("title.sendLinkTitle", { ns: "workspace" }),
            onClick: () => {
                toggleFormLink(props.docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "shareDoc",
            text: t("title.shareText", { ns: "workspace" }),
            title: t("title.shareTitle", { ns: "workspace" }),
            onClick: () => {
                onItemShare(props.docSelected);
                _deselect();
            },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "signDocument",
            text: t("title.signDocumentText", { ns: "workspace" }),
            title: t("title.signDocumentTitle", { ns: "workspace" }),
            onClick: async () => {
                onItemSignInvoked(props.docSelected);
                _deselect();
            },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,

            className:
                allowAdminAccess() && props.docSelected.AllowESign
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "subform",
            text: t("title.subformText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.subformTitle", { ns: "workspace" }),
            subMenuProps: {
                items: props.subformItems,
            },
            disabled:
                (!props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                    props.docSelected.Suspended ||
                    props.docSelected.Closed
                    ? true
                    : false,
            className:
                props.docSelected.HasSubForm && allowAdminAccess()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "edit",
            text: t("title.editText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.editTitle", { ns: "workspace" }),
            onClick: () => {
                onItemInvoked(props.docSelected);
                _deselect();
            },
            disabled:
                (!props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                isOptionDisabled(
                    CheckoutOptionsVisibilityType.UpdateWebForm,
                    props.docSelected
                ),
            className: allowUpdate(props.docSelected as IDocument)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "executed",
            text: t("title.executedText", { ns: "workspace" }),
            title: t("title.executedTitle", { ns: "workspace" }),
            onClick: () => {
                onExecuted(props.docSelected);
                _deselect();
            },
            className:
                allowAdminAccess() && props.docSelected.Executed === true
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
    ];
    const menuItemsMobile: IContextualMenuItem[] = [
        {
            key: "activity",
            text: t("title.activityText", { ns: "workspace" }),
            title: t("title.activityTitle", { ns: "workspace" }),
            onClick: () => {
                onItemStatusInvoked(props.docSelected);
                _deselect();
            },
        },
        {
            key: "attachment",
            text: t("title.attachmentText", { ns: "workspace" }),
            title: t("title.attachmentTitle", { ns: "workspace" }),
            onClick: () => {
                onDocumentAttachment(props.docSelected);
                _deselect();
            },
        },
        {
            key: "checkout",
            text: t("title.checkoutText", { ns: "workspace" }),
            title: t("title.checkoutTitle", { ns: "workspace" }),
            subMenuProps:
                props.docSelected.CheckedOut == false
                    ? undefined
                    : {
                        items: [
                            {
                                key: "undoCheckout",
                                text: t("title.undoCheckoutText", {
                                    ns: "workspace",
                                }),
                                title: t("title.undoCheckoutTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    UndoCheckOut(true);
                                },
                                disabled: isOptionDisabled(
                                    CheckoutOptionsVisibilityType.UndoCheckout,
                                    props.docSelected
                                ),
                            },
                        ],
                    },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className: /*props.docSelected.AllowCheckInOut ? classNames.showMenu : */classNames.hideMenu,
        },
        {
            key: "clone",
            text: t("title.cloneText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.cloneTitle", { ns: "workspace" }),
            onClick: () => {
                onClone(props.docSelected);
                _deselect();
            },
            disabled:
                !props.docSelected.ActiveSubscription &&
                sessionStorage.getItem("billingActive") === "true",
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "delete",
            text: t("title.deleteText", { ns: "workspace" }),
            title: t("title.deleteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemDelete(props.docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Delete,
                props.docSelected
            ),
            className:
                allowAdminAccess() && disabledCheckIn()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "manage",
            text: t("title.manageText", { ns: "workspace" }),
            title: t("title.manageTitle", { ns: "workspace" }),
            subMenuProps: {
                items: [
                    {
                        key: "close",
                        text: props.docSelected.Closed
                            ? t("title.reOpenText", { ns: "workspace" })
                            : t("title.closeText", { ns: "workspace" }),
                        title: props.docSelected.Closed
                            ? t("title.reOpenTitle", { ns: "workspace" })
                            : t("title.closeTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemClose(props.docSelected);
                            _deselect();
                        },
                    },
                    {
                        key: "suspend",
                        text: props.docSelected.Suspended
                            ? t("title.resumeText", { ns: "workspace" })
                            : t("title.suspendText", { ns: "workspace" }),
                        title: props.docSelected.Suspended
                            ? t("title.resumeTitle", { ns: "workspace" })
                            : t("title.suspendTitle", { ns: "workspace" }),
                        onClick: () => {
                            onItemSuspended(props.docSelected);
                            _deselect();
                        },
                    },
                ],
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.Manage,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "note",
            text: t("title.noteText", { ns: "workspace" }),
            title: t("title.noteTitle", { ns: "workspace" }),
            onClick: () => {
                onItemNotesInvoked(props.docSelected);
                _deselect();
            },
        },
        {
            key: "preview",
            text: t("title.previewText", { ns: "workspace" }),
            title: t("title.previewTitle", { ns: "workspace" }),
            onClick: () => {
                onItemPreview(props.docSelected);
                _deselect();
            },
        },
        {
            key: "Remove",
            text: t("title.removeText", { ns: "workspace" }),
            title: t("title.removeTitle", { ns: "workspace" }),
            onClick: () => {
                onItemRemove(props.docSelected);
                _deselect();
            },
            className:
                props.docSelected.UserName === Helper.getUsername()
                    ? classNames.hideMenu
                    : classNames.showMenu,
        },
        {
            key: "Renewals",
            text: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            title: t("title.renewalsRecord", { ns: "renewalsalerts" }),
            onClick: () => {
                setCurrentRenewalItem({
                    Name: props.docSelected.Description,
                    TemplateId: props.docSelected.TemplateId,
                    Id: 0,
                    RecordId: props.docSelected.Id
                })
                setShowRenewals(true)
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendMail",
            text: t("title.sendMailText", { ns: "workspace" }),
            title: t("title.sendMailTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSend(props.docSelected);
                _deselect();
            },
            className: getAllowSendMail(props.docSelected)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "sendLink",
            text: t("title.sendLinkText", { ns: "workspace" }),
            title: t("title.sendLinkTitle", { ns: "workspace" }),
            onClick: () => {
                toggleFormLink(props.docSelected);
                _deselect();
            },
            disabled: isOptionDisabled(
                CheckoutOptionsVisibilityType.WebformLink,
                props.docSelected
            ),
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "shareDoc",
            text: t("title.shareText", { ns: "workspace" }),
            title: t("title.shareTitle", { ns: "workspace" }),
            onClick: () => {
                onItemShare(props.docSelected);
                _deselect();
            },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className: allowAdminAccess()
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "signDocument",
            text: t("title.signDocumentText", { ns: "workspace" }),
            title: t("title.signDocumentTitle", { ns: "workspace" }),
            onClick: () => {
                onItemSignInvoked(props.docSelected);
                _deselect();
            },
            disabled:
                props.docSelected.Suspended || props.docSelected.Closed
                    ? true
                    : false,
            className:
                allowAdminAccess() && props.docSelected.AllowESign
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "subform",
            text: t("title.subformText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.subformTitle", { ns: "workspace" }),
            subMenuProps: {
                items: props.subformItems,
            },
            disabled:
                (!props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                    props.docSelected.Suspended ||
                    props.docSelected.Closed
                    ? true
                    : false,
            className:
                props.docSelected.HasSubForm && allowAdminAccess()
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "edit",
            text: t("title.editText", { ns: "workspace" }),
            title:
                !props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true"
                    ? props.docSelected.SubscriptionStatusMessage
                    : t("title.editTitle", { ns: "workspace" }),
            onClick: () => {
                onItemInvoked(props.docSelected);
                _deselect();
            },
            disabled:
                (!props.docSelected.ActiveSubscription &&
                    sessionStorage.getItem("billingActive") === "true") ||
                isOptionDisabled(
                    CheckoutOptionsVisibilityType.UpdateWebForm,
                    props.docSelected
                ),
            className: allowUpdate(props.docSelected as IDocument)
                ? classNames.showMenu
                : classNames.hideMenu,
        },
        {
            key: "version",
            name: "Version",
            text: t("title.versionText", { ns: "workspace" }),
            title: t("title.versionTitle", { ns: "workspace" }),
            onClick: () => {
                if (
                    allowAdminAccessOnLoad(props.docSelected) &&
                    props.docSelected.Versions > 1
                ) {
                    onItemVersionInvoked(props.docSelected);
                }
                _deselect();
            },
            className:
                props.docSelected.Versions > 1
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
        {
            key: "executed",
            text: t("title.executedText", { ns: "workspace" }),
            title: t("title.executedTitle", { ns: "workspace" }),
            onClick: () => {
                onExecuted(props.docSelected);
                _deselect();
            },
            className:
                allowAdminAccess() && props.docSelected.Executed === true
                    ? classNames.showMenu
                    : classNames.hideMenu,
        },
    ];

    const checkIncheckOut = async (checkIncheckOut: boolean) => {
        const req: ICheckinCheckoutDocumentOnlyRequest = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            DocumentId: props.docSelected.Id.toString(),
            ApiKey: "-1",
            Autoprovision: true,
            Checkout: checkIncheckOut,
            Path: await Helper.getSite(),
        };
        Helper.checkinCheckoutDocumentOnly(req).then((data) => {
            _deselect();
            props.updateInterval();
        });
    };
    const UndoCheckOut = async (checkIncheckOut: boolean) => {
        const req: ICheckinCheckoutDocumentOnlyRequest = {
            AccountId: Helper.getDefaultAccount(),
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            DocumentId: props.docSelected.Id.toString(),
            ApiKey: "-1",
            Autoprovision: true,
            Checkout: checkIncheckOut,
            Path: await Helper.getSite(),
        };
        Helper.UndoCheckoutDocumentOnly(req).then((data) => {
            _deselect();
            props.updateInterval();
        });
    };

    const menuProps: IContextualMenuProps = {
        onMenuOpened: () => {
            setDocumentId(props.docSelected.Id);
        },
        shouldFocusOnMount: true,
        items: menuItems,
    };

    const _deselect = () => {
        props.selectionState.setAllSelected(false);
    };

    const _update = () => {
        _deselect();
        props.update();
    };
    const menuPropsClosed: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItems.filter(
            (f) =>
                f.key != "signDocument" &&
                f.key != "checkout" &&
                f.key != "edit"
        ), //menuItems.filter(f => f.key!= "checkout" &&  f.key!= "Sign" &&  f.key!= "Update")
    };
    const menuPropsMobile: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsMobile.filter((f) => f.key != "download"),
    };
    const menuPropsMobileClosed: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: menuItemsMobile.filter(
            (f) =>
                f.key != "download" &&
                f.key != "signDocument" &&
                f.key != "checkout" &&
                f.key != "edit"
        ), //menuItems.filter(f => f.key!= "checkout" &&  f.key!= "Sign" &&  f.key!= "Update")
    };
    const onCreateUpdate = (id: string) => {
        setDocumentId(parseInt(id));
        setModalId(parseInt(id));
        setLayoutLoaded(false);

        setModalTitle(t("title.modal", { ns: "preview" }));
        setSpinnerText(t("text.spinner", { ns: "common" }));

        let jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            CultureName: "",
            Released: false,
            emailMoniker: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        const preview = async () => {
            setAllowDocx(false);
            const req: IDocumentRequest = {
                UserName: Helper.getUsername(),
                SessionKey: Helper.getSessionKey(),
                AccountId: Helper.getDefaultAccount(),
                DocumentId: "-1",
                Id: id,
                IsPreview: true,
                IsDownload: false,
                IsDocx: false,
            };

            await Helper.getDocumentOnlyTemplateId(templateId.toString(), true)
                .then((data) => {
                    try {
                        jsonBag = JSON.parse(data.PropertyBag) as IPropertyBag;
                        setAllowDocx(jsonBag.AllowDocX);
                        setShowPreview(!jsonBag.SupressPreview)
                        if (!jsonBag.SupressPreview) {
                            Helper.getDocumentPreview(req).then((res) => {
                                if (res.Success) {
                                    setLayoutLoaded(true);
                                    setPreviewURL(res.UrlPath);
                                    setPreviewMime(res.mimeType);
                                }
                            });
                        }
                    } catch (reason) {
                        console.log(reason);
                    }
                })
                .catch((reason) => {
                    console.log(reason);
                });
        };

        props.update();
        preview();
        if (props.selectionState.getSelectedIndices().length >= 1)
            props.selectionState.setAllSelected(false);
    };

    const onCreateUpdateDraft = () => {
        props.update();
        if (props.selectionState.getSelectedIndices().length >= 1)
            props.selectionState.setAllSelected(false);
    };

    const getErrorMessage = (value: string): string => {
        if (value.length > 0) {
            setRequiredNote(true);
        } else {
            setRequiredNote(false);
            return t("text.noteRequired", { ns: "recordmanage" });
        }

        return "";
    };

    async function closeDocument(isClosing: boolean) {

        const request = {
            TemplateId: templateId,
            DocumentId: documentId.toString(),
            Close: isClosing,
            Suspend: false,
            Note: note,
        };

        let { data }: any = await HttpRequest.postWithSession('DocumentOnlyClose', request)
        const response = data

        if (response.Success) {
            props.update();
            hideAddNoteModal();
        } else {
            console.log(response.ExceptionMessage);
        }
    }
    async function suspendDocument(isSuspending: boolean) {
        const request = {
            DocumentId: documentId.toString(),
            Close: false,
            Suspend: isSuspending,
            Note: note,
        };

        let { data }: any = await HttpRequest.postWithSession('DocumentOnlySuspend', request)
        const response = data

        if (response.Success) {
            props.update();
            hideAddNoteModal();
        } else {
            console.log(response.ExceptionMessage);
        }
    }
    const sendAction = () => {
        switch (modalId) {
            case 1:
                closeDocument(true);
                break;
            case 2:
                closeDocument(false);
                break;
            case 3:
                suspendDocument(true);
                break;
            case 4:
                suspendDocument(false);
                break;
        }
        hideAddNoteModal();
    };

    function goToLoc(loc: string) {
        if (sessionStorage.getItem("entityId") === "Not In Teams") {
            window.open(`${branding.helpPath}#${loc}`, "_blank");
        } else {
            props.showHelpTeams(loc);
        }
    }

    function showHelp(ev: React.MouseEvent<HTMLElement>) {
        toggleTeachingBubbleVisible();
        var toggle = !teachingBubbleVisible;
        if (toggle) {
            showCoachmarkS();
            showCoachmarkG();
        } else {
            hideCoachmarkS();
            hideCoachmarkG();
        }

        props.showCoachmarkT(toggle);
        props.showCoachmarkC(toggle);
        props.showCoachmarkA(toggle);
        props.showCoachmarkS(toggle);
    }

    function DownloadDoc() {
        onItemDownload(docSelected!, downloadDocX);
        if (!docSelected?.CheckedOut) checkIncheckOut(true);
    }

    const toggleSearchActive = useConstCallback((value: boolean) => {
        const site = Helper.getSiteSelected();
        props.toggleSearchActive(value);
    });
    const hideFormLink = () => {
        setshowFormLink(false);
        setDocumentId(-1);
        setModalTitle("");
        setTemplateName("");
        setEmailMoniker("");
    };

    const toggleFormLink = (item: IDocument) => {
        var emailMoniker = "";
        var allowDocx = false;
        var sendMail = false;
        var documentShare = false;
        var jsonBag: IPropertyBag = {
            Clon: "",
            Culture: "",
            Released: false,
            emailMoniker: "",
            CultureName: "",
            AllowDocX: false,
            AllowSendMail: false,
            DocumentCreation: false,
            DocumentUpdate: false,
            DocumentDelete: false,
            DocumentShare: false,
            TemplateEdit: false,
            TemplateShare: false,
            TemplateDelete: false,
            DefaultDescription: "",
            attachment: {} as AttachmentSettings,
            SupressPreview: false,
            DocumentDelivery: "None",
            RedirectURL: ""
        };

        if (item.TemplatePropertyBag != null) {
            var propBag = atob(item.TemplatePropertyBag);
            try {
                jsonBag = JSON.parse(propBag);
                emailMoniker = jsonBag.emailMoniker;
                allowDocx = jsonBag.AllowDocX;
                sendMail = jsonBag.AllowSendMail;
                documentShare = jsonBag.DocumentShare;
            } catch (error) { }
        }
        setTemplateId(item.TemplateId);
        setDocumentId(item.Id);
        setPropertyBag(jsonBag);
        setModalTitle("FormLink" + item.Id);
        setTemplateName(item.Description);
        setEmailMoniker(emailMoniker);
        setshowFormLink(true);
        setAllowDocx(allowDocx);
        setOwnerUser(item!.UserName!);
        setTemplateCategory(item!.Category!);
        setDocumentShare(documentShare);
        setDocumentDescription(`${item.Description}`);
        setTemplateVersion(item.TemplateVersion);
    };

    const GetDataDocument = async (item: IDocument) => {
        const req: IDocumentRequest = {
            UserName: Helper.getUsername(),
            SessionKey: Helper.getSessionKey(),
            AccountId: Helper.getDefaultAccount(),
            DocumentId: "-1",
            Id: item.Id.toString(),
            IsPreview: false,
            IsDownload: false,
            IsDocx: false,
        };
        var res = await Helper.getDocumentDownload(req);

        if (res.Success === undefined || !res.Success) {
            if (item.Versions < res.Versions) {
                setVersion(res.Versions);
                props.update();
                var itemDocument: IDocument = {
                    Id: item.Id,
                    AccountId: item.AccountId,
                    TemplateId: item.TemplateId,
                    UserName: item.UserName,
                    Description: item.Description,
                    Data: item.Data,
                    DateCreated: item.DateCreated,
                    DateUpdated: item.DateUpdated,
                    Name: item.Name,
                    MimeType: item.MimeType,
                    Culture: item.Culture,
                    IconName: item.IconName,
                    Versions: res.Versions,
                    IsCustom: item.IsCustom,
                    LastActivity: item.LastActivity,
                    Closed: item.Closed,
                    Suspended: item.Suspended,
                    LastNote: item.LastNote,
                    Category: item.Category,
                    AllowCheckInOut: item.AllowCheckInOut,
                    AllowESign: item.AllowESign,
                    AllowDocumentUpdate: item.AllowDocumentUpdate,
                    AllowNotification: item.AllowNotification,
                    CheckedOut: item.CheckedOut,
                    CheckedOutUser: item.CheckedOutUser,
                    CheckedOutDateTime: item.CheckedOutDateTime,
                    TemplatePropertyBag: item.TemplatePropertyBag,
                    Owner: item.Owner,
                    CoOwner: item.CoOwner,
                    Tickler: item.Tickler,
                    Attachments: item.Attachments,
                    AudienceName: item.AudienceName,
                    TemplateVersion: item.TemplateVersion,
                    FullName: item.FullName,
                    OnBehalfUserFullName: item.OnBehalfUserFullName,
                    OnBehalfUser: item.OnBehalfUser,
                    PrimaryId: item.PrimaryId,
                    SubRecords: item.SubRecords,
                    Executed: item.Executed,
                    HasSubRecord: item.HasSubRecord,
                    CampaignId: item.CampaignId,
                    ActiveSubscription: item.ActiveSubscription,
                    SubscriptionStatusMessage: item.SubscriptionStatusMessage,
                };

                props.onDocumentSelected(itemDocument);
                return itemDocument;
            }
        }
        return item;
    };
    const fetchMoreData = () => {
        var page = 0;

        isAdvanceSearch =
            sessionStorage.getItem("tempAdvanceSearch") != "" &&
                sessionStorage.getItem("tempAdvanceSearch") != null
                ? true
                : false;

        if (props.documentList.length != props.totalRecordCount) {
            if (
                props.isloadComplet &&
                sessionStorage.getItem("updateApp") !== "true"
            ) {
                if (
                    sessionStorage.getItem("pageCount")! !== undefined &&
                    sessionStorage.getItem("pageCount")! !== null
                ) {
                    page = parseInt(sessionStorage.getItem("pageCount")!);
                }
                page = page + 1;
                sessionStorage.setItem("pageCount", page.toString());
                if (props.isSearchActive) {
                    var SearchValue = sessionStorage.getItem("SearchValue");
                    props.onSearch(SearchValue);
                } else if (isAdvanceSearch) {
                    var tempAdvanceSearch = JSON.parse(
                        sessionStorage.getItem("tempAdvanceSearch")!
                    ) as IAdvanceSearch;
                    tempAdvanceSearch.PageNo = page;
                    sessionStorage.setItem(
                        "tempAdvanceSearch",
                        JSON.stringify(tempAdvanceSearch)
                    );
                    props.onAdvanceSearch(tempAdvanceSearch);
                } else {
                    props.updateDocumentList(
                        Helper.getSessionKey(),
                        Helper.getDefaultAccount(),
                        Helper.getUsername()
                    );
                }
            }
        }
    };

    const loadSubforms = async (docItem: IDocument) => {
        await RestrictSubFormsService.GetSubFormRestrictionFullInfo({ TemplateId: docItem.TemplateId }).then(response => {
            var auxMenu: IContextualMenuItem[] = [];

            response.forEach((e: any) => {
                var m: IContextualMenuItem = {
                    key: e.Id,
                    text: e.Category + " - " + e.DisplayName,
                    title: e.Description,
                    subMenuProps: {
                        items: [
                            {
                                key: "createSubform",
                                text: t("title.subformCreateText", {
                                    ns: "workspace",
                                }),
                                title: t("title.subformCreateTitle", {
                                    ns: "workspace",
                                }),
                                onClick: (item: any) => {
                                    setDraftId(-1);
                                    setDocumentId(props.docSelected.Id);
                                    onItemSubformInvoked({
                                        ...e,
                                        DefaultDescription:
                                            docItem?.Description,
                                    });
                                    _deselect();
                                },
                            },
                            {
                                key: "sendSubform",
                                text: t("title.publishText", {
                                    ns: "workspace",
                                }),
                                title: t("title.publishTitle", {
                                    ns: "workspace",
                                }),
                                onClick: () => {
                                    setDocumentId(props.docSelected.Id);
                                    setshowFormTemplateLink(true);
                                    setDocumentOnlyAudienceId(
                                        e.DocumentOnlyAudienceId
                                    );
                                    setTemplateName(e.DisplayName);
                                    setTemplateId(e.TemplateId);
                                    setOwnerName(e.OwnerFullName);
                                    setOwnerUser(e.OwnerUserName);
                                    setTemplateCategory(e.Category);
                                    setAudienceName(e.AudienceName!);

                                    var jsonBag: IPropertyBag = {
                                        Clon: "",
                                        Culture: "",
                                        CultureName: "",
                                        Released: false,
                                        emailMoniker: "",
                                        AllowDocX: false,
                                        AllowSendMail: false,
                                        DocumentCreation: false,
                                        DocumentUpdate: false,
                                        DocumentDelete: false,
                                        DocumentShare: false,
                                        TemplateEdit: false,
                                        TemplateShare: false,
                                        TemplateDelete: false,
                                        DefaultDescription: "",
                                        attachment: {} as AttachmentSettings,
                                        SupressPreview: false,
                                        DocumentDelivery: "None",
                                        RedirectURL: ""
                                    };
                                    var moniker = "";
                                    if (e.PropertyBag != null) {
                                        var propBag = atob(e.PropertyBag);
                                        try {
                                            jsonBag = JSON.parse(propBag);
                                            moniker = jsonBag.emailMoniker;
                                        } catch (error) { }
                                    }
                                    setEmailMoniker(moniker);
                                    setIsSubform(true);
                                    _deselect();
                                },
                            },
                        ],
                    },
                };

                auxMenu.push(m);
            });

            auxMenu.sort((a: IContextualMenuItem, b: IContextualMenuItem) => {
                return a.text!.toLowerCase() < b.text!.toLowerCase() ? -1 : 1;
            })
            props.setSubformItems(auxMenu);
        })
    };

    const loadIntegrationsEvents = async (item: IDocument) => {
        OutboundServices.GetManualEvents({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: Helper.getSiteSelected(),
            SK: Helper.getSessionKey(),
            TemplateId: item.TemplateId,
            UserName: Helper.getUsername(),
            DocumentId: item.Id,
        }).then((res) => {
            if (res.length > 0) {
                var manualMenu: IContextualMenuItem[] = [];
                res.forEach((event: any) => {
                    var m: IContextualMenuItem = {
                        key: event.Id.toString(),
                        text: event.Description,
                        title: event.Description,
                        onClick: () => {
                            OutboundServices.ExecuteEvent({
                                AccountId: Helper.getDefaultAccount(),
                                ApiKey: "-1",
                                Autoprovision: true,
                                DocumentId: item.Id,
                                EventId: event.Id,
                                Path: Helper.getSiteSelected(),
                                SK: Helper.getSessionKey(),
                                TemplateId: event.TemplateId,
                                UserName: Helper.getUsername(),
                                Async: false,
                            }).then((res: any) => {
                                _deselect();
                                setModalContent(res);
                                showIntegrationTestModal();
                            });
                        },
                    };
                    manualMenu.push(m);
                });
                setManualOutboundIntegrations(manualMenu);
            } else {
                setManualOutboundIntegrations([]);
            }
        });
    };

    const hideFormTemplateLink = () => {
        setshowFormTemplateLink(false);
    };
    const onRenderHeader: IRenderFunction<IDetailsHeaderProps> = (
        props,
        defaultRender
    ) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<
            IDetailsColumnRenderTooltipProps
        > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const onCampaignItem = (item: IDocument) => {
        setSpinnerText("Loading attachments...");
        SetShowCampaignList(true);
        setTemplateId(item!.TemplateId!);
        setCampaignId(item!.CampaignId!);
    };
    const hideCampaignList = useConstCallback(() => {
        SetShowCampaignList(false);
    });

    const hideModalSubscription = useConstCallback(() => {
        var isWeb =
            !isMobile && sessionStorage.getItem("entityId") === "Not In Teams";

        if (isWeb) {
            props.SubscriptionDisabled(true, props.lastDocSelectedIsOwner());
        }

        props.setDisplaySubscriptionRedirectionMessage(false);
        props.setHideSubscriptionDialog(true);
    });

    return (
        <>
            {showCustomForm && (
                <CustomForm
                    allowDrafts={isSubform ? true : false}
                    targetUser={Helper.getUsername()}
                    parentId={documentId}
                    draftId={draftId}
                    setDraftId={setDraftId}
                    showCustomForm={showCustomForm}
                    toggleCustomForm={toggleCustomForm}
                    siteOptions={siteOptions}
                    siteSelected={siteSelected}
                    accountId={Helper.getDefaultAccount()}
                    username={Helper.getUsername()}
                    sessionKey={Helper.getSessionKey()}
                    templateId={templateId.toString()}
                    templateName={templateName}
                    templateDescription={templateDescription}
                    data={data}
                    documentId={documentId.toString()}
                    update={onCreateUpdate}
                    culture={culture}
                    templateVersion={templateVersion}
                    isCreator={isCreator}
                    isOwner={isOwner}
                    isCoOwner={iscoOwner}
                    ownerName={ownerName}
                    ownerUser={ownerUser}
                    templateCategory={templateCategory}
                    audienceName={audienceName}
                    emailMoniker={emailMoniker}
                    documentCreation={documentCreation}
                    documentUpdate={documentUpdate}
                    templateEdit={templateEdit}
                    isSubform={isSubform}
                    isprimaryDocumentIdForSecondary={
                        isprimaryDocumentIdForSecondary
                    }
                    onUpdateInterval={props.onUpdateInterval}
                    isUpdateInterval={props.isUpdateInterval}
                    defaultDescription={defaultDescription}
                    setDefaultDescription={setDefaultDescription}
                    documentDescription={documentDescription}
                    setDocumentDescription={setDocumentDescription}
                    audienceMasterId={audienceMasterId}
                    toggleQueueModal={toggleQueueModal}
                    updateDocumentList={props.update}
                />
            )}
            <PreviewScreen
                showModal={showPreview}
                hideModal={hidePreview}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                documentId={documentId}
                allowDocX={allowDocx}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                URLPath={previewURL}
                mimeType={previewMime}
            />
            <QueueModal Modal={QueueCustomModal.Modal} close={QueueCustomModal.close} isUpdate={isRecordUpdate} isLink={false} isError={isRecordError} />
            <Confirmation
                showModal={showConfirm}
                hideModal={hideConfirm}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={deleteId}
                message={deleteMessage}
                confirmDelete={confirmDelete}
                actionText={actionText}
                modalId={undefined}
            />
            <EmailWidget
                showModal={showEmail}
                hideModal={hideEmail}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                documentDescription={documentDescription}
                setSpinnerText={setSpinnerText}
                setLayoutLoaded={setLayoutLoaded}
                username={username}
                id={documentId}
                templateId={templateId}
                emailMoniker={emailMoniker}
                allowDocx={allowDocx}
            />
            <VersionScreen
                showModal={showVersion}
                hideModal={hideVersion}
                modalTitle={modalTitle}
                allowDocX={allowDocx}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                documentDescription={documentDescription}
                versionList={versionList}
                update={_update}
                version={version}
            />
            <Form
                modalId={modalId}
                modalTitle={modalTitle}
                showModal={showModal}
                hideModal={hideModal}
                layout={layout}
                layoutLoaded={layoutLoaded}
                update={_update}
                spinnerText={spinnerText}
                setSpinnerText={setSpinnerText}
                setLayoutLoaded={setLayoutLoaded}
                documentId={documentId}
            />
            <ShareDocument
                allowDocx={allowDocx}
                templateVersion={templateVersion}
                showModal={showShare}
                showShare={showShare}
                hideModal={hideShare}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                TemplateId={templateId}
                documentId={documentId}
                documentDescription={documentDescription}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                propertyBag={propertyBag}
                ownerName={ownerName}
                ownerUser={ownerUser}
                templateCategory={templateCategory}
                templateName={templateName}
                audienceName={audienceName}
                emailMoniker={emailMoniker}
                documentShare={documentShare}
                audienceOwnerUserName={documentAudienceOwner}
            />
            <RenewalsAndAlertsEdit
                showModal={showRenewals}
                onDismissModal={onBeforeDismiss}
                item={currentRenewalItem}
                lockAtStartup={false}
            />
            <Attachment
                showAttachment={showAttachment}
                hideAttachament={hideAttachment}
                documentId={documentId}
                docSource={"normal"}
                username={Helper.getUsername()}
                documentDescription={documentDescription}
                update={_update}
                templateId={templateId}
                activeSubscription={activeSubscription}
                subscriptionStatusMessage={subscriptionStatusMessage}
                showRules={false}
                showAttachments={true}
                targetUser={Helper.getUsername()}
            />
            <Executed
                showExecuted={showExecuted}
                hideExecuted={hideExecuted}
                spinnerText={spinnerText}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                documentId={documentId}
                documentName={documentName}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
            />
            <UploadDocument
                modalTitle={modalTitle}
                showUploadDoc={showUploadDoc}
                siteSelected={siteSelected}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                documentId={props.docSelected.Id}
                templateId={props.docSelected.TemplateId}
                sessionKey={Helper.getSessionKey()}
                setToggleUploadDoc={setToggleUploadDoc}
                checkIncheckOut={checkIncheckOut}
                update={_update}
            />
            <ActivityStatus
                showActivity={showActivity}
                documentDescription={documentDescription}
                hideModalActivity={hideModalActivity}
                documentId={documentId}
                modalTitle={modalTitle}
            />
            <Notes
                showNotes={showNotes}
                hideModalNotes={hideModalNotes}
                documentId={documentId}
                documentDescription={documentDescription}
                modalTitle={modalTitle}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                update={_update}
                owner={owner}
                coowner={coowner}
                user={username}
            />
            <Signers
                showSigners={showSigners}
                hideModalSigners={hideModalSigners}
                documentId={documentId}
                modalTitle={modalTitle}
                documentDescription={documentDescription}
                siteSelected={Helper.getSiteSelected()}
                accountId={Helper.getDefaultAccount()}
                username={Helper.getUsername()}
                sessionKey={Helper.getSessionKey()}
                update={_update}
                templateId={templateId}
                documentName={documentName}
                version={documentVersion}
            />
            <Confirmation
                showModal={showRemove}
                hideModal={hideRemove}
                modalTitle={modalTitle}
                spinnerText={spinnerText}
                layoutLoaded={layoutLoaded}
                id={modalId}
                message={removeeMessage}
                confirmDelete={confirmRemove}
                actionText={actionText}
            />

            <FormDocumentLink
                showModal={showFormLink}
                modalTitle={templateName}
                documentId={documentId.toString()}
                onDismissModal={hideFormLink}
                allowDocx={allowDocx}
                templateId={templateId.toString()}
                templateVersion={templateVersion}
                ownerName={ownerName}
                ownerUser={ownerUser}
                documentDescription={documentDescription}
                isSubform={false}
            />

            <SubForms
                templateOnlyList={props.templateOnlyList}
                cacheAudience={cacheAudience}
                isOptionDisabled={isOptionDisabled}
                showModal={isModalSubform}
                hideSubForm={hideModalSubform}
                modalTitle={modalTitle}
                documentId={documentId.toString()}
                setSubrecords={setSubrecords}
                update={_update}
                isDisabled={isDisabled}
                isloadComplet={true}
                templateId={props.docSelected.TemplateId}
                isTicklerQueue={isTicklerQueue}
            />


            <FormTemplateLink
                showModal={showFormTemplateLink}
                onDismissModal={hideFormTemplateLink}
                templateId={templateId.toString()}
                name={templateName}
                documentOnlyPrimaryId={documentId}
                ownerUser={ownerUser}
            />

            <Campaign
                showCampaign={showCampaignList}
                hideCampaign={hideCampaignList}
                isNew={false}
                campaignId={campaignId}
                templateId={templateId}
            />

            <Confirmation
                showModal={isNoAllowSign}
                hideModal={hideConfirm}
                modalTitle={modalTitle}
                spinnerText={t("text.spinner", { ns: "common" })}
                layoutLoaded={layoutLoaded}
                id={deleteId}
                message={message}
                confirmDelete={hideConfirm}
                actionText={t("Common.Button.Accept", { ns: "common" })}
            />

            {isCoachmarkVisibleG && (
                <Coachmark
                    target={targetButtonG.current}
                    positioningContainerProps={{
                        directionalHint: DirectionalHint.bottomAutoEdge,
                    }}
                >
                    <TeachingBubbleContent
                        headline={t("title.records", { ns: "coachmarks" })}
                        hasCloseButton
                        closeButtonAriaLabel={t("Common.Button.Close", {
                            ns: "common",
                        })}
                        primaryButtonProps={{
                            children: t("Common.Button.See more info", {
                                ns: "common",
                            }),
                            title: t("title.coachmarkButton", {
                                ns: "workspace",
                            }),
                            onClick: () => {
                                goToLoc("records");
                            },
                        }}
                        onDismiss={hideCoachmarkG}
                    >
                        {t("text.records1", { ns: "coachmarks" })}
                        <br />
                        <br />
                        {t("text.records2", { ns: "coachmarks" })}
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            {isCoachmarkVisibleS && (
                <Coachmark
                    target={targetButtonS.current}
                    positioningContainerProps={{
                        directionalHint: DirectionalHint.bottomCenter,
                    }}
                >
                    <TeachingBubbleContent
                        headline={t("title.search", { ns: "coachmarks" })}
                        hasCloseButton
                        closeButtonAriaLabel={t("Common.Button.Close", {
                            ns: "common",
                        })}
                        primaryButtonProps={{
                            children: t("Common.Button.See more info", {
                                ns: "common",
                            }),
                            title: t("title.coachmarkButton", {
                                ns: "workspace",
                            }),
                            onClick: () => {
                                goToLoc("search");
                            },
                        }}
                        onDismiss={hideCoachmarkS}
                    >
                        {t("text.search1", { ns: "coachmarks" })}
                        <br />
                        <br />
                        {t("text.search2", { ns: "coachmarks" })}
                    </TeachingBubbleContent>
                </Coachmark>
            )}

            <Stack tokens={stackTokens}>
                <div
                    className="ms-Grid-row"
                    style={
                        {
                            //      height: '4vh',
                            // paddingRight: '20px',
                            //  backgroundColor:'red',
                            // margin:'1%'
                        }
                    }
                >
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        style={{
                            //  top: "10px",
                            //  paddingTop: "12px" ,
                            marginTop: "1%",
                        }}
                    >

                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg9">
                            <div
                                className="ms-hiddenMdDown"
                                style={{ backgroundColor: "#ff", display: "flex", gap: "15px", alignItems: "center" }}
                            >
                                <Toggle label={t("Common.Toggle.Assigned", { ns: "common" })} checked={props.isAssignedChecked} inlineLabel onText="" offText="" onChange={props.onChangeAssigned} styles={{ root: { paddingLeft: "8px", marginBottom: 0 }, label: { fontWeight: "normal", fontSize: "18px" } }} />
                                {!props.docSelected.ActiveSubscription &&
                                    sessionStorage.getItem("billingActive") ===
                                    "true" ? (
                                        <Icon
                                            iconName="WarningSolid"
                                            title={
                                                props.docSelected
                                                    .SubscriptionStatusMessage ??
                                                "Audience Subscription disabled"
                                            }
                                            style={{
                                                paddingRight: "5px",
                                            }}
                                            styles={iconStylesWarn}
                                        />
                                    ) : null}
                                <Icon
                                    iconName="ExploreData"
                                    styles={iconStyles}
                                />
                                <span
                                    title={t("title.recordsAvaliable", {
                                        ns: "workspace",
                                    })}
                                    style={{
                                        backgroundColor: "#fff",
                                        fontSize: "18px",
                                        fontFamily:
                                            '"Segoe UI", "Segoe UI Web(West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                                    }}
                                >
                                    {props.totalRecordCount +
                                        " " +
                                        t("DashboardComponent.Records", {
                                            ns: "workspace",
                                        })}{" "}
                                </span>
                                <DraftList CurrentDraftId={-1}
                                    onCreateUpdateDraft={onCreateUpdateDraft}
                                />
                                <RenewalsAndAlertsWorkSpace

                                    templateOnlyList={props.templateOnlyList}
                                    selectionState={props.selectionState}
                                    update={props.update}
                                    onUpdateInterval={props.onUpdateInterval}
                                    isUpdateInterval={props.isUpdateInterval}


                                />
                                <div ref={targetButtonG}></div>
                            </div>
                            <div
                                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-hiddenLgUp"
                                style={{ backgroundColor: "#fff" }}
                            >
                                <div className="ms-Grid-col ms-sm4">
                                    {!props.docSelected.ActiveSubscription &&
                                        sessionStorage.getItem("billingActive") ===
                                        "true" ? (
                                            //<Icon iconName="WarningSolid" title={props.docSelected.SubscriptionStatusMessage??"Audience Subscription disabled"} style={{ top: "5px", paddingRight: "5px" }}   styles={iconStylesWarn} />
                                            <>
                                                {isMobile ? (
                                                    <>
                                                        <IconButton
                                                            id="WarninSubscription"
                                                            onClick={
                                                                toggleIsCalloutVisibleSubscription
                                                            }
                                                            text={
                                                                isCalloutVisibleSubscription
                                                                    ? "Audience enable"
                                                                    : "Audience disabled"
                                                            }
                                                            styles={
                                                                iconButtonStyles
                                                            }
                                                            iconProps={WarningIcon}
                                                            style={{
                                                                fontSize: "18px",
                                                                height: "18px",
                                                                width: "18px",
                                                                color: "red",
                                                            }}
                                                        />

                                                        {isCalloutVisibleSubscription && (
                                                            <Callout
                                                                className={
                                                                    contentStyles.callout
                                                                }
                                                                target="#WarninSubscription"
                                                                onDismiss={
                                                                    toggleIsCalloutVisibleSubscription
                                                                }
                                                                role="status"
                                                                aria-live="assertive"
                                                            >
                                                                <DelayedRender>
                                                                    <Text variant="small">
                                                                        Audience
                                                                        disabled
                                                                </Text>
                                                                </DelayedRender>
                                                            </Callout>
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                </div>
                                <div className="ms-Grid-col ms-sm8"></div>
                            </div>
                            <div
                                className="ms-Grid-col ms-sm12 ms-hiddenLgUp"
                                style={{
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    float: "left",
                                }}
                            >
                                <div
                                    className="ms-Grid-col ms-sm3"
                                    style={{
                                        marginLeft: 0,
                                        paddingLeft: 0,
                                        float: "left",
                                        marginRight: "0",
                                    }}
                                >
                                    <IconButton
                                        iconProps={{
                                            iconName: "ExploreData",
                                            styles: iconExplorerStyles,
                                        }}
                                        title="ExploreData"
                                    />
                                </div>
                                <div
                                    className="ms-Grid-col ms-sm4"
                                    style={{
                                        marginLeft: 0,
                                        paddingLeft: 0,
                                        float: "left",
                                        color: "black !important",
                                    }}
                                >
                                    <ActionButton
                                        allowDisabledFocus
                                        text={
                                            props.totalRecordCount.length > 4
                                                ? props.totalRecordCount
                                                    .toString()
                                                    .slice(0, 4)
                                                : props.totalRecordCount
                                        }
                                        title={props.totalRecordCount}
                                        styles={{
                                            root: {
                                                marginLeft: 0,
                                                paddingLeft: 0,
                                                color: "black",
                                                fontSize: "18px",
                                                top: "3%",
                                            },
                                        }}
                                    />
                                </div>
                                <div
                                    className="ms-Grid-col ms-sm4"
                                    style={{
                                        marginLeft: 0,
                                        paddingLeft: 0,
                                        fontSize: "18px",
                                        top: "3%",
                                    }}
                                >
                                    <DraftList CurrentDraftId={-1}
                                        onCreateUpdateDraft={
                                            onCreateUpdateDraft
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            ref={targetButtonS}
                            className="ms-Grid-col ms-sm6 ms-md6 ms-lg3"
                            style={{
                                display: "flex",
                                position: "fixed",
                                right: "20px",
                            }}
                        >
                            <div
                                className={
                                    isMobile
                                        ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                        : "ms-Grid-col ms-sm11 ms-md11 ms-lg11"
                                }
                            >
                                <Search
                                    onSearch={props.onSearch}
                                    onSearchClear={props.onSearchClear}
                                    onAdvanceSearch={props.onAdvanceSearch}
                                    toggleLoadComplet={props.toggleLoadComplet}
                                    toggleSearchActive={toggleSearchActive}
                                    searchBoxValue={props.searchBoxValue}
                                    setSearchBoxValue={props.setSearchBoxValue}
                                />
                            </div>

                            {!isMobile ? (
                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                    <IconButton
                                        iconProps={helpIcon}
                                        ariaLabel={t("Common.Button.Help", {
                                            ns: "common",
                                        })}
                                        onClick={showHelp}
                                        title={t("Common.Button.Help", {
                                            ns: "common",
                                        })}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-hiddenLgUp">
                    <Toggle label={t("Common.Toggle.Assigned", { ns: "common" })} checked={props.isAssignedChecked} inlineLabel onText="" offText="" onChange={props.onChangeAssigned} styles={{ root: { marginBottom: 0 }, label: { fontWeight: "normal", fontSize: "18px" } }} />
                </div>
                <div
                    id="scrollableDivGeneral"
                    style={{
                        top: "5px",
                        // marginTop: '20px',
                        height: "85vh",
                    }}
                >
                    <ReactTooltip
                        id="NoteToolTip"
                        place="top"
                        type="info"
                        effect="float"
                        bodyMode={true}
                        backgroundColor={branding.theme.palette.themeDark}
                        multiline={true}
                    />

                    <ReactTooltip
                        id="LastActivityToolTip"
                        place="top"
                        type="dark"
                        effect="float"
                        bodyMode={true}
                        backgroundColor={branding.theme.palette.themeDark}
                        multiline={true}
                    />
                    
                    <InfiniteScroll
                        dataLength={props.documentList.length}
                        next={fetchMoreData}
                        hasMore={props.isloadComplet}
                        loader={
                            props.documentList.length > 0 &&
                                !props.isloadComplet ? (
                                    <Spinner
                                        size={SpinnerSize.large}
                                        label="Loading..."
                                        ariaLive="assertive"
                                    />
                                ) : null
                        }
                        scrollableTarget="scrollableDivGeneral"
                        height="86vh"
                    >
                        <DetailsList
                            items={props.documentList || []}
                            compact={false}
                            columns={columns}
                            getKey={getKey}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={props.selectionState}
                            selectionMode={SelectionMode.single}
                            selectionPreservedOnEmptyClick={false}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            onRenderDetailsHeader={onRenderHeader}
                        />
                    </InfiniteScroll>
                </div>
            </Stack>

            <Modal isOpen={showAddNote} isBlocking={true}>
                <Stack>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div
                                style={{ padding: "0px" }}
                                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                            >
                                <div className={classNames.header}>
                                    <span id="actionTitle">{actionTitle}</span>
                                    <IconButton
                                        styles={iconButtonNoteModalStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                        onClick={hideAddNoteModal}
                                        title={t("Common.Button.Close", {
                                            ns: "common",
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div className={contentStyles.bodyNote}>
                                    <TextField
                                        label={t("label.note", {
                                            ns: "recordmanage",
                                        })}
                                        onGetErrorMessage={getErrorMessage}
                                        id="enterNoteDescription"
                                        maxLength={1000}
                                        title={t("title.note", {
                                            ns: "recordmanage",
                                        })}
                                        placeholder={t("placeholder.note", {
                                            ns: "recordmanage",
                                        })}
                                        required
                                        onChange={(_, v) => {
                                            setNote(v!);
                                        }}
                                        value={note}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={sendAction}
                                        disabled={!requiredNote}
                                        text={actionTitleButton}
                                        title={t("title.close", {
                                            ns: "recordmanage",
                                            Name: actionTitle,
                                        })}
                                    />
                                    <DefaultButton
                                        onClick={hideAddNoteModal}
                                        text={t("Common.Button.Cancel", {
                                            ns: "common",
                                        })}
                                        title={t("Common.Button.Cancel", {
                                            ns: "common",
                                        })}
                                    />
                                </DialogFooter>
                            </div>
                        </div>
                        <br />
                    </div>
                </Stack>
            </Modal>
            <Copy
                hideModalClone={hideModalClone}
                showModalClone={showModalClone}
                onCreateUpdate={onCreateUpdate}
                templateId={templateId.toString()}
                documentId={documentId.toString()}
                templateVersion={templateVersion}
                description={shortcut}
            />
            <Modal
                isOpen={showConfirmationModal}
                isBlocking={true}
                containerClassName={contentStyles.containerSaveModal}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div
                            style={{ padding: "0px" }}
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        >
                            <div className={contentStyles.header}>
                                <span id="Confirmation">
                                    {downloadModalTitle}
                                </span>
                                <IconButton
                                    styles={iconButtonStylesPrompt}
                                    iconProps={cancelIcon}
                                    ariaLabel={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                    onClick={hideConfirmationModal}
                                    title={t("Common.Button.Close", {
                                        ns: "common",
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-lg12">
                            <div className={contentStyles.body}>
                                {downloadModalText}
                            </div>
                        </div>
                    </div>
                    <div
                        className="ms-Grid-row"
                        style={{
                            bottom: "10px",
                            right: "12px",
                            position: "absolute",
                        }}
                    >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={DownloadDoc}
                                    text={t("Common.Button.Yes", {
                                        ns: "common",
                                    })}
                                    title={t("title.btnYes", {
                                        ns: "checkinout",
                                    })}
                                />
                                <DefaultButton
                                    onClick={hideConfirmationModal}
                                    text={t("Common.Button.No", {
                                        ns: "common",
                                    })}
                                    title={t("Common.Button.No", {
                                        ns: "common",
                                    })}
                                />
                            </DialogFooter>
                        </div>
                    </div>
                </div>
            </Modal>
            <DownloadConfirmation
                showDownloadConfirmationModal={showDownloadConfirmationModal}
                downloadType={downloadType}
                callbackDownloadConfirmation={
                    downloadDocumentOnlyDataPointCallback
                }
                setShowDownloadConfirmationModal={
                    setShowDownloadConfirmationModal
                }
                downloadDocSelected={downloadDocSelected}
            />
            <Dialog
                hidden={props.hideSubscriptionDialog}
                dialogContentProps={dialogSubscriptionContentProps}
                modalProps={modalProps}
            >
                {dialogSubscriptionContentProps.subText.indexOf("past due") >=
                    0 && props.docSelected.Owner
                    ? "You will be redirected to Billing Plan where a plan can be purchased."
                    : null}
                {dialogSubscriptionContentProps.subText.indexOf("storage") >=
                    0 && props.docSelected.Owner
                    ? "You will be redirected to Billing Plan where you can upgrade your plan."
                    : null}
                {dialogSubscriptionContentProps.subText.indexOf(
                    "submission limit has been met"
                ) >= 0 && props.docSelected.Owner
                    ? "You will be redirected to Billing Plan where you can upgrade your plan."
                    : null}
                <DialogFooter>
                    <PrimaryButton onClick={hideModalSubscription} text="Ok" />
                </DialogFooter>
            </Dialog>

            <TestIntegrationModal
                showModal={isIntegrationTestModalOpen}
                hideModal={hideIntegrationTestModal}
                content={modalContent}
                setModalContent={setModalContent}
            />
        </>
    );
}

export default DataPanel;
