import React, { useEffect, useState } from 'react';
import { Stack, IStackStyles, IStackProps } from '@fluentui/react/lib/Stack';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Pivot, PivotLinkSize, PivotItem } from '@fluentui/react/lib/Pivot';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { IDropdownStyles, Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import Helper, { ICreateDocumentRequest, ICUManageClientPlansRequest, IRequest, Registration } from '../../Helpers/Helper';
import { getTheme, IScrollablePaneStyles, Link, mergeStyleSets, ScrollablePane, Text } from 'office-ui-fabric-react';
import '../../Custom.css';
import { result } from 'lodash';
import { Label, Spinner, SpinnerSize } from '@fluentui/react';
import ReactDOM from 'react-dom';
import { useBranding } from "../../hooks/useBranding";
import useAuth from '../../hooks/useAuth';
import HttpRequest from '../../services/HttpRequest';
import axios from 'axios';
import ManageClientPlansService, {ICreateUpdateManageClientPlansRequest} from "../../services/ManageClientPlans";
import MerchantService from "../../services/Merchant";
import BillingStateService, {ICreateUpdateOwnerNameRequest} from "../../services/BillingState";




initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/");

const theme = getTheme();
const { palette, fonts, semanticColors } = theme;
const contentStyles = mergeStyleSets({
    pane: {
        maxWidth: '98%',

    },

});
const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = { root: { width: "100%", paddingRight:"10px" } }
const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: "100%", paddingRight:"5px" } },
}
const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: contentStyles.pane };

const dropdownStyles: Partial<IDropdownStyles> = {
	dropdown: { width: 300 },
}
   
const options: IDropdownOption[] = [
    { key: ' ', text: ' ' },
    { key: 'Mr.', text: 'Mr.' },
    { key: 'Mrs.', text: 'Mrs.' },
    { key: 'Ms.', text: 'Ms.' },
    { key: 'Dr.', text: 'Dr.' },
]

const timeZones: IDropdownOption[] = [
	{ key: 'Dateline Standard Time', text: '(UTC-12:00) International Date Line West' },
	{ key: 'UTC-11', text: '(UTC-11:00) Coordinated Universal Time-11' },
	{ key: 'Aleutian Standard Time', text: '(UTC-10:00) Aleutian Islands' },
	{ key: 'Hawaiian Standard Time', text: '(UTC-10:00) Hawaii' },
	{ key: 'Marquesas Standard Time', text: '(UTC-09:30) Marquesas Islands' },
	{ key: 'Alaskan Standard Time', text: '(UTC-09:00) Alaska' },
	{ key: 'UTC-09', text: '(UTC-09:00) Coordinated Universal Time-09' },
	{ key: 'Pacific Standard Time (Mexico)', text: '(UTC-08:00) Baja California' },
	{ key: 'UTC-08', text: '(UTC-08:00) Coordinated Universal Time-08' },
	{ key: 'Pacific Standard Time', text: '(UTC-08:00) Pacific Time (US & Canada)' },
	{ key: 'US Mountain Standard Time', text: '(UTC-07:00) Arizona' },
	{ key: 'Mountain Standard Time (Mexico)', text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' },
	{ key: 'Mountain Standard Time', text: '(UTC-07:00) Mountain Time (US & Canada)' },
	{ key: 'Central America Standard Time', text: '(UTC-06:00) Central America' },
	{ key: 'Central Standard Time', text: '(UTC-06:00) Central Time (US & Canada)' },
	{ key: 'Easter Island Standard Time', text: '(UTC-06:00) Easter Island' },
	{ key: 'Central Standard Time (Mexico)', text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' },
	{ key: 'Canada Central Standard Time', text: '(UTC-06:00) Saskatchewan' },
	{ key: 'SA Pacific Standard Time', text: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco' },
	{ key: 'Eastern Standard Time (Mexico)', text: '(UTC-05:00) Chetumal' },
	{ key: 'Eastern Standard Time', text: '(UTC-05:00) Eastern Time (US & Canada)' },
	{ key: 'Haiti Standard Time', text: '(UTC-05:00) Haiti' },
	{ key: 'Cuba Standard Time', text: '(UTC-05:00) Havana' },
	{ key: 'US Eastern Standard Time', text: '(UTC-05:00) Indiana (East)' },
	{ key: 'Turks and Caicos Standard Time', text: '(UTC-05:00) Turks and Caicos' },
	{ key: 'Paraguay Standard Time', text: '(UTC-04:00) Asuncion' },
	{ key: 'Atlantic Standard Time', text: '(UTC-04:00) Atlantic Time (Canada)' },
	{ key: 'Venezuela Standard Time', text: '(UTC-04:00) Caracas' },
	{ key: 'Central Brazilian Standard Time', text: '(UTC-04:00) Cuiaba' },
	{ key: 'SA Western Standard Time', text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
	{ key: 'Pacific SA Standard Time', text: '(UTC-04:00) Santiago' },
	{ key: 'Newfoundland Standard Time', text: '(UTC-03:30) Newfoundland' },
	{ key: 'Tocantins Standard Time', text: '(UTC-03:00) Araguaina' },
	{ key: 'E. South America Standard Time', text: '(UTC-03:00) Brasilia' },
	{ key: 'SA Eastern Standard Time', text: '(UTC-03:00) Cayenne, Fortaleza' },
	{ key: 'Argentina Standard Time', text: '(UTC-03:00) City of Buenos Aires' },
	{ key: 'Greenland Standard Time', text: '(UTC-03:00) Greenland' },
	{ key: 'Montevideo Standard Time', text: '(UTC-03:00) Montevideo' },
	{ key: 'Magallanes Standard Time', text: '(UTC-03:00) Punta Arenas' },
	{ key: 'Saint Pierre Standard Time', text: '(UTC-03:00) Saint Pierre and Miquelon' },
	{ key: 'Bahia Standard Time', text: '(UTC-03:00) Salvador' },
	{ key: 'UTC-02', text: '(UTC-02:00) Coordinated Universal Time-02' },
	{ key: 'Mid-Atlantic Standard Time', text: '(UTC-02:00) Mid-Atlantic - Old' },
	{ key: 'Azores Standard Time', text: '(UTC-01:00) Azores' },
	{ key: 'Cabo Verde Standard Time', text: '(UTC-01:00) Cabo Verde Is.' },
	{ key: 'Coordinated Universal Time', text: '(UTC) Coordinated Universal Time' },
	{ key: 'GMT Standard Time', text: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' },
	{ key: 'Greenwich Standard Time', text: '(UTC+00:00) Monrovia, Reykjavik' },
	{ key: 'Sao Tome Standard Time', text: '(UTC+00:00) Sao Tome' },
	{ key: 'Morocco Standard Time', text: '(UTC+01:00) Casablanca' },
	{ key: 'W. Europe Standard Time', text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
	{ key: 'Central Europe Standard Time', text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
	{ key: 'Romance Standard Time', text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
	{ key: 'Central European Standard Time', text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
	{ key: 'W. Central Africa Standard Time', text: '(UTC+01:00) West Central Africa' },
	{ key: 'Jordan Standard Time', text: '(UTC+02:00) Amman' },
	{ key: 'GTB Standard Time', text: '(UTC+02:00) Athens, Bucharest' },
	{ key: 'Middle East Standard Time', text: '(UTC+02:00) Beirut' },
	{ key: 'Egypt Standard Time', text: '(UTC+02:00) Cairo' },
	{ key: 'E. Europe Standard Time', text: '(UTC+02:00) Chisinau' },
	{ key: 'Syria Standard Time', text: '(UTC+02:00) Damascus' },
	{ key: 'West Bank Gaza Standard Time', text: '(UTC+02:00) Gaza, Hebron' },
	{ key: 'South Africa Standard Time', text: '(UTC+02:00) Harare, Pretoria' },
	{ key: 'FLE Standard Time', text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
	{ key: 'Jerusalem Standard Time', text: '(UTC+02:00) Jerusalem' },
	{ key: 'Russia TZ 1 Standard Time', text: '(UTC+02:00) Kaliningrad' },
	{ key: 'Sudan Standard Time', text: '(UTC+02:00) Khartoum' },
	{ key: 'Libya Standard Time', text: '(UTC+02:00) Tripoli' },
	{ key: 'Namibia Standard Time', text: '(UTC+02:00) Windhoek' },
	{ key: 'Arabic Standard Time', text: '(UTC+03:00) Baghdad' },
	{ key: 'Turkey Standard Time', text: '(UTC+03:00) Istanbul' },
	{ key: 'Arab Standard Time', text: '(UTC+03:00) Kuwait, Riyadh' },
	{ key: 'Belarus Standard Time', text: '(UTC+03:00) Minsk' },
	{ key: 'Russia TZ 2 Standard Time', text: '(UTC+03:00) Moscow, St. Petersburg' },
	{ key: 'E. Africa Standard Time', text: '(UTC+03:00) Nairobi' },
	{ key: 'Iran Standard Time', text: '(UTC+03:30) Tehran' },
	{ key: 'Arabian Standard Time', text: '(UTC+04:00) Abu Dhabi, Muscat' },
	{ key: 'Astrakhan Standard Time', text: '(UTC+04:00) Astrakhan, Ulyanovsk' },
	{ key: 'Azerbaijan Standard Time', text: '(UTC+04:00) Baku' },
	{ key: 'Russia TZ 3 Standard Time', text: '(UTC+04:00) Izhevsk, Samara' },
	{ key: 'Mauritius Standard Time', text: '(UTC+04:00) Port Louis' },
	{ key: 'Saratov Standard Time', text: '(UTC+04:00) Saratov' },
	{ key: 'Georgian Standard Time', text: '(UTC+04:00) Tbilisi' },
	{ key: 'Volgograd Standard Time', text: '(UTC+04:00) Volgograd' },
	{ key: 'Caucasus Standard Time', text: '(UTC+04:00) Yerevan' },
	{ key: 'Afghanistan Standard Time', text: '(UTC+04:30) Kabul' },
	{ key: 'West Asia Standard Time', text: '(UTC+05:00) Ashgabat, Tashkent' },
	{ key: 'Russia TZ 4 Standard Time', text: '(UTC+05:00) Ekaterinburg' },
	{ key: 'Pakistan Standard Time', text: '(UTC+05:00) Islamabad, Karachi' },
	{ key: 'Qyzylorda Standard Time', text: '(UTC+05:00) Qyzylorda' },
	{ key: 'India Standard Time', text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
	{ key: 'Sri Lanka Standard Time', text: '(UTC+05:30) Sri Jayawardenepura' },
	{ key: 'Nepal Standard Time', text: '(UTC+05:45) Kathmandu' },
	{ key: 'Central Asia Standard Time', text: '(UTC+06:00) Astana' },
	{ key: 'Bangladesh Standard Time', text: '(UTC+06:00) Dhaka' },
	{ key: 'Omsk Standard Time', text: '(UTC+06:00) Omsk' },
	{ key: 'Myanmar Standard Time', text: '(UTC+06:30) Yangon (Rangoon)' },
	{ key: 'SE Asia Standard Time', text: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
	{ key: 'Altai Standard Time', text: '(UTC+07:00) Barnaul, Gorno-Altaysk' },
	{ key: 'W. Mongolia Standard Time', text: '(UTC+07:00) Hovd' },
	{ key: 'Russia TZ 6 Standard Time', text: '(UTC+07:00) Krasnoyarsk' },
	{ key: 'Novosibirsk Standard Time', text: '(UTC+07:00) Novosibirsk' },
	{ key: 'Tomsk Standard Time', text: '(UTC+07:00) Tomsk' },
	{ key: 'China Standard Time', text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
	{ key: 'Russia TZ 7 Standard Time', text: '(UTC+08:00) Irkutsk' },
	{ key: 'Malay Peninsula Standard Time', text: '(UTC+08:00) Kuala Lumpur, Singapore' },
	{ key: 'W. Australia Standard Time', text: '(UTC+08:00) Perth' },
	{ key: 'Taipei Standard Time', text: '(UTC+08:00) Taipei' },
	{ key: 'Ulaanbaatar Standard Time', text: '(UTC+08:00) Ulaanbaatar' },
	{ key: 'Aus Central W. Standard Time', text: '(UTC+08:45) Eucla' },
	{ key: 'Transbaikal Standard Time', text: '(UTC+09:00) Chita' },
	{ key: 'Tokyo Standard Time', text: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
	{ key: 'North Korea Standard Time', text: '(UTC+09:00) Pyongyang' },
	{ key: 'Korea Standard Time', text: '(UTC+09:00) Seoul' },
	{ key: 'Russia TZ 8 Standard Time', text: '(UTC+09:00) Yakutsk' },
	{ key: 'Cen. Australia Standard Time', text: '(UTC+09:30) Adelaide' },
	{ key: 'AUS Central Standard Time', text: '(UTC+09:30) Darwin' },
	{ key: 'E. Australia Standard Time', text: '(UTC+10:00) Brisbane' },
	{ key: 'AUS Eastern Standard Time', text: '(UTC+10:00) Canberra, Melbourne, Sydney' },
	{ key: 'West Pacific Standard Time', text: '(UTC+10:00) Guam, Port Moresby' },
	{ key: 'Tasmania Standard Time', text: '(UTC+10:00) Hobart' },
	{ key: 'Russia TZ 9 Standard Time', text: '(UTC+10:00) Vladivostok' },
	{ key: 'Lord Howe Standard Time', text: '(UTC+10:30) Lord Howe Island' },
	{ key: 'Bougainville Standard Time', text: '(UTC+11:00) Bougainville Island' },
	{ key: 'Russia TZ 10 Standard Time', text: '(UTC+11:00) Chokurdakh' },
	{ key: 'Magadan Standard Time', text: '(UTC+11:00) Magadan' },
	{ key: 'Norfolk Standard Time', text: '(UTC+11:00) Norfolk Island' },
	{ key: 'Sakhalin Standard Time', text: '(UTC+11:00) Sakhalin' },
	{ key: 'Central Pacific Standard Time', text: '(UTC+11:00) Solomon Is., New Caledonia' },
	{ key: 'Russia TZ 11 Standard Time', text: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky' },
	{ key: 'New Zealand Standard Time', text: '(UTC+12:00) Auckland, Wellington' },
	{ key: 'UTC+12', text: '(UTC+12:00) Coordinated Universal Time+12' },
	{ key: 'Fiji Standard Time', text: '(UTC+12:00) Fiji' },
	{ key: 'Kamchatka Standard Time', text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old' },
	{ key: 'Chatham Islands Standard Time', text: '(UTC+12:45) Chatham Islands' },
	{ key: 'UTC+13', text: '(UTC+13:00) Coordinated Universal Time+13' },
	{ key: 'Tonga Standard Time', text: '(UTC+13:00) Nuku\'alofa' },
	{ key: 'Samoa Standard Time', text: '(UTC+13:00) Samoa' },
	{ key: 'Line Islands Standard Time', text: '(UTC+14:00) Kiritimati Island' }	
]

function RegistrationStepper(props: any) {
	const { branding } = useBranding()
	const [timeZone, setTimeZone] = useState('Eastern Standard Time')
	const [title, setTitle] = useState("")
	const [firstName, setFirstName] = useState("")
	const [middleName, setMiddleName] = useState("")
	const [lastName, setLastName] = useState("")
	const [activationGuid, setActivationGuid] = useState("")
	const [disableNext, setDisableNext] = useState(true)
	const [disableValidation, setDisableValidation] = useState(true)
	const [disableToS, setDisableTos] = useState(true)
	const [isChecked, setIsChecked] = useState(false)
	const [isApply, setisApply] = React.useState(false);
	const [MerchantId, setMerchantId] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
    const [spinnerText, setSpinnerText] = useState("")
    const { isAuthenticated } = useAuth();

	const reqManageClientPlan: ICreateUpdateManageClientPlansRequest = {
		Id: -1,
		OwnerName: Helper.getUsername(),
		Active: true,
		Comment: "NA",
		ContactEmailAddress: Helper.getUsername(),
		ContactFirstName: "NA",
		ContactLastName: "NA",
		ContactPhoneNumber: "1111111111",
		OrganizationAddress: "NA",
		OrganizationCity: "NA",
		OrganizationCountry: "NA",
		OrganizationName: "NA",
		OrganizationPostalCode: "NA",
		SubscriptionId: "-1",
		BillingPlanId: -1,
		OrganizationState: "NA",
		Operation: "update",
		Updated: Date.now().toString(),
		Created: Date.now().toString(),
	}

	const registerUser = () => {

		let reg = new Registration()
		reg.email = props.email;
		reg.title = title;
		reg.firstName = firstName;
		reg.middleName = middleName;
		reg.lastName = lastName;
		reg.timeZone = timeZone;

		Helper.setRegistration(reg).then(r => {
			props.onStep("405")
        })
	}

	const validateGuid = () => {
		setisApply(true)
		setIsLoading(true)
		setSpinnerText("Loading data, please wait...")
		let reg = new Registration()
		reg.email = props.email
		setSpinnerText("Validating data, please wait...")
		if (firstName === "") {
			Helper.getRegistration(reg).then(r => {

				reg.title = r.Title
				reg.firstName = r.FirstName
				reg.middleName = r.MiddleName
				reg.lastName = r.LastName
				reg.timeZone = r.TimeZone
				reg.activationGuid = activationGuid

				Helper.setRegistration(reg).then(r => {
					setSpinnerText("Data Validated, please wait...")
					if (r.Success === false) {
						setSpinnerText("Error Ocurred, please wait...")
                        setIsLoading(false)
                        setTimeout(function () {
                            setisApply(false)
                            setIsLoading(false)
                        }, 5000);
						return
					}
					

					
					
				})
			})
		} else {

			reg.email = props.email
			reg.title = title
			reg.firstName = firstName
			reg.middleName = middleName
			reg.lastName = lastName
			reg.timeZone = timeZone
			reg.activationGuid = activationGuid

			Helper.setRegistration(reg).then(async r => {
				setSpinnerText("Data Validated, please wait...")
				if (r.Success === "false") {
					setSpinnerText("Error Ocurred, please wait...")
                    setIsLoading(false)
                    setTimeout(function () {
                        setisApply(false)
                        setIsLoading(false)
                    }, 5000);
					return
                }
				else if (r.UserStatus === "REGISTERED") {
					setSpinnerText("User Registered, please wait...")
					var userName = Helper.getUsername()
					await loadPlan()
				}
				//props.onClose()
			})
        }
	}

	const validateToS = () => {
		props.onStep("404")

		setTimeout(function () {
			let element = ReactDOM.findDOMNode(document.getElementById("ScrollPaneUserlist")) as Element
			if (element !== null) { element.children[1].classList.add("scrollVisible") }
		}, 1000);
		
	}

	const enableValidationOnPaste = () => {
		if (activationGuid.length > 30) {
			setDisableValidation(false)
		}
		else {
			setDisableValidation(true)
        }
    }


	const checkValidationCodeLength = () => {
		if (activationGuid.length > 31) {
			setDisableValidation(false)
        }
    }

	const checkFirstNameAndLastName = () => {
		if (lastName.length > 1 && firstName.length>1) {
			setDisableNext(false)
		}
	}


	const checkRequiredfields = (step: string) => {

		if (step === "404") {
			if (firstName !== "" &&
				lastName !== "" /*&&
				timeZone !== ""*/) {
				setDisableNext(false)
			} else {
				setDisableNext(true)
            }
		} else if (step === "405") {
			if (activationGuid !== "") {
				setDisableValidation(false)
			} else {
				setDisableValidation(true)
			}
		} else if (step === "400") {
			return false
		}

		return false
	}

	const onChangeCheckbox = (ev: any, checked: any) => {
		setIsChecked(!!checked);
		setDisableTos(!!isChecked)
	};

	const resendCode = () => {

		Helper.resendCode(props.email)
    }
	
	const loadPlan = async () => {
		setSpinnerText("Setting Free Plan, please wait...")
		var site = await Helper.getSite()!
		var res = await Helper.authenticate(Helper.getUsername(), site)
		if (res!.hasOwnProperty('SessionKey')) {
			const req: IRequest = {
				UserName: Helper.getUsername(),
				SK: Helper.getSessionKey(),
				AccountId: Helper.getDefaultAccount(),
				ApiKey: "-1",
				Autoprovision: true,
				Path: site,
			}

			var url = "configuration"
			const data2 = await fetch(url,{
				method: 'GET',
				headers: await Helper.getHeaders()
			})
			const response2 = await data2.json()
			var baseUrl = response2[0].Path

			let sitelocal = response2.filter((r: any) => r.Path == baseUrl)[0]
            setSpinnerText("Configuring Free Plan, please wait...")

            //This call is needed because the user is valid (AuthValid),
            //but no token until confirm account
            const headers = await HttpRequest.getAxiosHeaders();
            const { data: user } = await axios.get("isAuthenticated", {
                method: "GET",
                headers: headers
            })

            if (user.token !== null) {
                sessionStorage.setItem("x-identity", user.token)
            }

			await loadMerchant()
			if (sitelocal !== undefined) {
				await Helper.getAllPlan(req).then(async data => {
					if (data.length !== undefined && data.length > 0) {
						let DefaultPlan = await Helper.getDefaultPlan()
						let planD = data.filter(x => x.Name.toLowerCase() === DefaultPlan)[0]

						if (planD.Id > 0) {
							InitializeManageClientPlant(planD.Id)
						}
					}
				})
			}
		}
	}

	async function InitializeManageClientPlant(_defaultPlanId: number) {

		setSpinnerText("Finishing Free Plan Configuration, please wait...")

		reqManageClientPlan.Id = -1
		reqManageClientPlan.Operation = "create"
		reqManageClientPlan.BillingPlanId = _defaultPlanId
		reqManageClientPlan.ContactFirstName = firstName
		reqManageClientPlan.ContactLastName = lastName
		reqManageClientPlan.OrganizationName = firstName + " " + lastName

		await ManageClientPlansService.CreateUpdateManageClientPlansOwnerName(reqManageClientPlan)
			.then(async (data) => {
				reqManageClientPlan.Id = Number(data.Id)
				reqManageClientPlan.Operation = "update"
				reqManageClientPlan.Active = true
				await ManageClientPlansService.CreateUpdateManageClientPlansOwnerName(reqManageClientPlan)
					.then(() => {
						createUpdateBillingState("NA")
					})
					.catch((error) => {
						createUpdateBillingState(error.message ? error.message.toString() : error.toString())
					})
					.finally()


			})
			.catch()
			.finally()
	}
	async function createUpdateBillingState(error: string) {
		setSpinnerText("Activating Free Plan, please wait...")
		setisApply(true)

		const request: ICreateUpdateOwnerNameRequest = {
			Active: reqManageClientPlan.Active,
			BillingClientId: reqManageClientPlan.Id,
			BillingMerchantId: MerchantId,
			BillingPlanId: reqManageClientPlan.BillingPlanId,
			TransactionCode: "Free Plan",
			TransactionDescription: "Free Plan",
			TransactionError: error,
			TransactionId: "-1",
			TransactionName: "Free Plan",
			Operation: "createUpdate",
			NextDueDate: undefined,
			OwnerName: Helper.getUsername()
		}

		await BillingStateService.CreateUpdateOwnerName(request)
			.then((data) => {
				setSpinnerText("Free Plan Activated, please wait...")
			})
			.catch((error) => {
			})
			.finally(() => {
				setisApply(false)
			})
	}

	const loadMerchant = async () => {
		return await MerchantService.GetAllMerchant()
			.then((data) => {
				if (data.length > 0) {
					const _MerchantActive = data.filter(s => s.Active == true)
					setMerchantId(Number(_MerchantActive[0].Id))
				}
			})
			.catch()
			.finally()
	}
	
	return (
<>
		{!isLoading ? 
			<Pivot selectedKey={props.step} linkSize={PivotLinkSize.large}>
			<PivotItem headerText="ToS" itemKey="400">
			<div>
				<Stack horizontal tokens={stackTokens} styles={stackStyles}>
					<Stack {...columnProps}>
						<br/>
						<h3> By creating an account you agree to our </h3>
							<text>
								<Link  href="/Kim-Document-Data-Processing-Agreement-January-2022.pdf" target="_blank" title="Data Processing Agreement">Data Processing Agreement</Link>,
                                <Link href="/Kim-Document-Terms-and-Conditions.pdf" target="_blank" title="Terms and Conditions"> Terms and Conditions</Link>
                                <Link href="/kim-document-privacy-policy-2021.pdf" target="_blank" title="Privacy Policy"> Privacy Policy</Link> and to receive emails.
								<br/>
							</text>
							<div>
								<div style={{float:'left'}}>
									<Checkbox label="I have read and agree to the terms of service." title="Checking this boxes indicates that you have read and agree with terms of service." checked={isChecked} onChange={onChangeCheckbox} />
								</div>
								<div style={{float:'right'}}>
									<PrimaryButton text="Confirm" onClick={validateToS} disabled={disableToS} title={`Confirm indicates that you have read, accept and confirmed ${branding.appName} terms of service to adavance to the next step.`}/>
								</div>
							</div>
					</Stack>
				</Stack>
			</div>
			</PivotItem>
            <PivotItem headerText="Profile" itemKey="404">
			<div>
                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
				{/* <ScrollablePane id="ScrollPaneUserlist" key="ScrollPaneUserlist" style={{top:"110px", height:"40vh"}}> */}
				<div className='ms-Grid-row ' style={{height:'100%', margin:"2%", width:"90%"}}>
					<div className=' <div className="ms-Grid-col ms-sm12 " >'>
					<Stack {...columnProps}>
					
                        <TextField label="Email" disabled value={props.email} />
							<TextField label="First Name" required onChange={(_, v) => { setFirstName(v!); checkRequiredfields(props.step) }} />
							<TextField label="Last Name" required onChange={(_, v) => { setLastName(v!); checkRequiredfields(props.step) }} />
						<div style={{ display: "none" }}>
							<Dropdown
								placeholder=" "
								label="Time Zone"
								selectedKey={timeZone}
								options={timeZones}
								styles={dropdownStyles}
								onChange={(_, o) => setTimeZone(o!.key.toString())}
							/>
						</div>
						<br />
						<Text style={{ textAlign: "right" }}>
								<PrimaryButton text="Next" onClick={registerUser} onMouseEnter={checkFirstNameAndLastName} disabled={disableNext} />
						</Text>
						
                    </Stack>
					</div>
					</div>
					{/* </ScrollablePane> */}
				</Stack>
            </div>
			</PivotItem>
            <PivotItem headerText="Validate" itemKey="405">
				<Stack horizontal tokens={stackTokens} styles={stackStyles}>
					<Stack {...columnProps}>
						<br /><br />
						<p>Enter activation code sent to your email address and accept terms of service to validate.</p>
						<p>If you do not receive an email, please check your junk folder. If you still cannot see your activation email please use the contact email address at the bottom of the screen.</p>
						<MaskedTextField label="Activation Code"
							mask="******"
							required
							onMouseLeave={checkValidationCodeLength}
							onChange={(_, v) => { setActivationGuid(v!); checkRequiredfields(props.step) }}
							onBlur={() => { checkRequiredfields(props.step) }} />
							<Text style={{textAlign: "right"}}>
							<Link href="" onClick={resendCode}>
								Resend Activation Code
							</Link>
						</Text>
						<br />
						{!isApply?
						<Text style={{ textAlign: "right" }}>
					
							<PrimaryButton text="Validate" onClick={validateGuid} disabled={disableValidation} />
						</Text>
						:
						<div >
							<br/>
						<Spinner size={SpinnerSize.large} label="validating information.." ariaLive="assertive" />
						</div>}
                    </Stack>
				</Stack>

            </PivotItem>
			</Pivot>
			:
			<Stack>
				<Label />
				<Label />
				<Label />
				<Spinner size={SpinnerSize.medium} label={spinnerText} ariaLive="assertive" />
			</Stack>
		}
		</>
    );
}

export default RegistrationStepper