import React, { useEffect } from 'react';
import { Route, useLocation, useHistory, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import WebRegistration from './Components/Registration/WebRegistration';
import Help from './Components/Help/Help';
import { initializeIcons } from '@fluentui/react';
import DocumentLink from './Components/DocumentLink/DocumentLink';
import ActivityWrapper from './Components/Activity/ActivityWrapper';
import Secure from './Components/DocumentLink/Secure';
import ErrorPage from './Components/Error/Error';
import WebRegistrationAuth0 from './Components/Registration/WebRegistrationAuth0';
import CampaignWebForm from './Components/Campaign/CampaignWebForm';
import Unsubscribe from './Unsubscribe/Unsubscribe';
import Permissions from './Components/GraphApi/Permissions'
import Concent from './Components/GraphApi/Concent';
import { AuthCode, WithAuthCode } from './Components/Authentication/Index';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import Callback from './Components/OutboundIntegration/OAuth/Callback';
import { InitComponent } from './Components/InitComponent';
import Logout from './Components/Authentication/Components/Logout';
import AppProvider from './context/AppContext';
import { setIdleTime } from "./hooks/useIdle"
import { useBranding } from './hooks/useBranding';
import HelpMarketplace from "./Components/Help/HelpMarketplace";
import ExternalLinkAccess from "./Components/ExternalLinkAccess";

initializeFileTypeIcons(/* optional base url */);
initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/")

export interface ISubRecord {
    Id: number
    AccountId: number
    TemplateId: number
    UserName: string
    Description: string
    Data: string
    DateCreated: string
    DateUpdated: string
    Name: string
    MimeType: string
    Culture: string
    IconName: string
    Versions: number
    IsCustom: boolean
    LastActivity: string
    Closed: boolean
    Suspended: boolean
    LastNote: string
    Category: string
    AllowCheckInOut: boolean
    AllowESign: boolean
    AllowDocumentUpdate: boolean
    AllowNotification: boolean
    CheckedOut: boolean,
    CheckedOutUser: string,
    CheckedOutDateTime: string,
    TemplatePropertyBag: string,
    Owner: boolean,
    CoOwner: boolean,
    Tickler: string,
    Attachments: number,
    AudienceName: string,
    TemplateVersion: string,
    FullName: string,
    OnBehalfUserFullName: string,
    OnBehalfUser: string,
    PrimaryId: number
}

export interface IDocument {
    Id: number
    AccountId: number
    TemplateId: number
    UserName: string
    Description: string
    Data: string
    DateCreated: string
    DateUpdated: string
    Name: string
    MimeType: string
    Culture: string
    IconName: string
    Versions: number
    IsCustom: boolean
    LastActivity: string
    Closed: boolean
    Suspended: boolean
    LastNote: string
    Category: string
    AllowCheckInOut: boolean
    AllowESign: boolean
    AllowDocumentUpdate: boolean
    AllowNotification: boolean
    CheckedOut: boolean,
    CheckedOutUser: string,
    CheckedOutDateTime: string,
    TemplatePropertyBag: string,
    Owner: boolean,
    CoOwner: boolean,
    Tickler: string,
    Attachments: number,
    AudienceName: string,
    TemplateVersion: string,
    FullName: string,
    OnBehalfUserFullName: string,
    OnBehalfUser: string,
    PrimaryId: number,
    SubRecords: ISubRecord[],
    Executed: boolean,
    HasSubRecord: boolean,
    CampaignId: number,
    ActiveSubscription: boolean,
    SubscriptionStatusMessage: string
}

const subR: ISubRecord[] = []

export class Document implements IDocument {
    Id = -1;
    AccountId = -1
    TemplateId = -1
    UserName = ""
    Description = ""
    Data = ""
    DateCreated = ""
    DateUpdated = ""
    Name = ""
    MimeType = ""
    Culture = ""
    IconName = ""
    Versions = -1
    IsCustom = false
    LastActivity = ""
    Closed = false
    Suspended = false
    LastNote = ""
    Category = ""
    AllowCheckInOut = false
    AllowDocumentUpdate = false
    AllowESign = false
    AllowNotification = false
    CheckedOut = false
    CheckedOutUser = ""
    CheckedOutDateTime = ""
    TemplatePropertyBag = ""
    Owner = false
    CoOwner = false
    Tickler = ""
    Attachments = 0
    AudienceName = ""
    TemplateVersion = ""
    FullName = ""
    OnBehalfUserFullName = ""
    OnBehalfUser = ""
    PrimaryId = -1
    SubRecords = subR
    Executed = false
    HasSubRecord = false
    CampaignId = 0
    ActiveSubscription = true
    SubscriptionStatusMessage = ""
}

function App() {
    let location = useLocation();
    let _history = useHistory()
    const searchParameter = useLocation().search;

    useEffect(() => {

        try {
            sessionStorage.removeItem("OnBehalfUser")
            sessionStorage.removeItem("campaignTargetUser");
        } catch (Ex) {

        }

        const searchParam = new URLSearchParams(searchParameter)
        const func = searchParam.get('func')
        const documentId = searchParam.get('documentId')
        if (func != null && documentId != null) {
            sessionStorage.setItem("documentId", documentId)
            sessionStorage.setItem("func", func.toLowerCase().trim())
            _history.replace(location.pathname.replace(/^\/*/, "/"))
        }

        const importExternalPackage = searchParam.get('import');
        if (importExternalPackage != null) {
            sessionStorage.setItem("importExternalPackage", importExternalPackage)
            _history.replace(location.pathname.replace(/^\/*/, "/"))
        }


        const accept = searchParam.get('done')

        if (accept != null) {
            setIdleTime()
            _history.replace("/");
        }

    }, [])


    return (
        <AppProvider>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app" />
                    </Route>
                    <Route exact path="/registration">
                        <Registration />
                    </Route>
                    <Route exact path="/link/document" component={WithAuthCode(DocumentLink)} />
                    <Route exact path="/link/application" component={WithAuthCode(DocumentLink)} />
                    <Route exact path="/AuthCode">
                        <AuthCode location={location} />
                    </Route>
                    <Route exact path="/help">
                        <Help />
                    </Route>
                    <Route exact path="/helpformlinks">
                        <HelpMarketplace />
                    </Route>
                    <Route exact path="/helpmarketplace">
                        <HelpMarketplace />
                    </Route>
                    <Route exact path="/externalLinkAccess">
                        <ExternalLinkAccess />
                    </Route>
                    <Route exact path="/documentlink">
                        <DocumentLink isAccessCodeValid={true} />
                    </Route>
                    <Route exact path="/templatelink">
                        <DocumentLink />
                    </Route>
                    <Route exact path="/campaign">
                        <CampaignWebForm />
                    </Route>
                    <Route exact path="/activity">
                        <ActivityRoute />
                    </Route>
                    <Route exact path="/secure">
                        <Secure />
                    </Route>
                    {/* Callback for OutboundIntegration */}
                    <Route exact path="/oauth2/callback">
                        <Callback />
                    </Route>
                    <Route exact path="/error">
                        <Error />
                    </Route>
                    <Route exact path="/unsubscribe">
                        <Unsubscribe />
                    </Route>
                    <Route exact path="/app/permissions">
                        <Permissions />
                    </Route>
                    <Route exact path="/app/consent">
                        <Concent />
                    </Route>
                    <Route exact path="/app">
                        <InitComponent />
                    </Route>
                    <Route exact path="/logout">
                        <Logout />
                    </Route>
                    <Redirect from="//*" to="/*" />

                    <Route
                        path="*"
                        element={<Error />}
                    />
                </Switch>
            </Router>
        </AppProvider>
    )
}

const ActivityRoute = () => {
    const { getAppSettings } = useBranding()
    useEffect(() => {
        getAppSettings()
    }, [])
    return <ActivityWrapper />
}
export const Error = () => {
    let location = useLocation();
    const { getAppSettings } = useBranding()
    useEffect(() => {
        getAppSettings()
    }, [])
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    if (searchParams.get('tracking') !== null) {
        window.location.assign(window.location.origin + "/app")
        return (<></>)
    } else {
        return (
            <ErrorPage
                search={location.search}
            />
        )
    }
}
const Registration = () => {
    if (sessionStorage.getItem("SessionKey") !== null) {
        window.location.assign(window.location.origin + "/app")
        return <></>
    } else if (sessionStorage.getItem("entityId") === null || sessionStorage.getItem("entityId") === "Not In Teams")
        return (
            <Route exact path="/registration">  <WebRegistrationAuth0 /></Route>
        );
    else
        return (
            <WebRegistration
                email={sessionStorage.getItem("username")!}
            />
        );
}
/**
 * Implementation of TeamsBaseComponent
 */

export default App;